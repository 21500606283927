import React from 'react';
import '../../static/scss/error.scss';
import { ReactComponent as AlertSvg } from '../../static/icons/account/icon_alert.svg';
import { useTranslation } from 'react-i18next';

export const ErrorAccountCardExpired: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className='error-card-expired-account'>
            <AlertSvg height={20} className='me-2' />
            <span className='invalid'>{t('account-management.expired-card')}</span>
        </div>
    );
};
