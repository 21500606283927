import React from 'react';

interface IBreadCrumbItem extends React.HtmlHTMLAttributes<HTMLDivElement> {
    translation: string
};

export const BreadeCrumbItem: React.FC<IBreadCrumbItem> = ({ className, translation, id }) => {

    return (
        <div className="my-breadcrumb-item d-flex align-items-center">
            <div className={`badge badge-${className} d-flex justify-content-center align-items-center`}>{id}</div>
            <span className={`text text-${className}`}>{translation}</span>
        </div>
    );
};