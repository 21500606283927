import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import CustomButton from '../../../components/button/button';
import { Client } from '../../../context/clientContext';

export const ClientInformation: React.FC<{ clientValues: Client | null }> = ({ clientValues }) => {

    const { t } = useTranslation();
    const { push } = useHistory();

    const handlePageModification = (key: string) => {
        push(`account/edit/${key}`);
    };

    return (
        <div className="middle-container my-account-view">
            <div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                <div className="d-flex align-items-center">
                    <h2 className='mb-0 me-lg-4 account-title'>{t('account-management.client-information')}</h2>
                </div>
                <div>
                    <CustomButton
                        classNameType='mainWhite'
                        onClick={() => handlePageModification('account')}
                        buttonText={t('account-management.modification')}
                        buttonType='button'
                    />
                </div>
            </div>
            <div className='row flex-row mb-0 mb-lg-4'>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.name')}</span>
                        <span className='account-value'>{clientValues?.name || '-'}</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.last-name')}</span>
                        <span className='account-value'>{clientValues?.lastName || '-'}</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.first-name')}</span>
                        <span className='account-value'>{clientValues?.firstName || '-'}</span>
                    </div>
                </div>
            </div>
            <div className='row flex-row mb-0 mb-lg-4'>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.mail')}</span>
                        <span className='account-value'>{clientValues?.mail || '-'}</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="account-label-phone d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.phone')}</span>
                        <span className='account-value'>{clientValues?.phone || '-'}</span>
                    </div>
                </div>
            </div>
            <div className='row flex-row mb-0 mb-lg-4'>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.address')}</span>
                        <span className='account-value'>{clientValues?.address || '-'}</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.city')}</span>
                        <span className='account-value'>{clientValues?.city || '-'}</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.postal-code')}</span>
                        <span className='account-value'>{clientValues?.postalCode || '-'}</span>
                    </div>
                </div>
            </div>
            <div className='row flex-row mb-0 mb-lg-4'>
                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                    <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                        <span className='mr-1 text-bold'>{t('account-management.country')}</span>
                        <span className='account-value'>{clientValues?.country || '-'}</span>
                    </div>
                </div>
            </div>
            {
                clientValues?.type === 'COMPANY' && <div className='row flex-row mb-0 mb-lg-4 '>
                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                        <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                            <span className='mr-1 text-bold'>{t('account-management.company-name')}</span>
                            <span className='account-value'>{clientValues?.companyName || '-'}</span>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                        <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                            <span className='mr-1 text-bold'>{t('account-management.company-id')}</span>
                            <span className='account-value'>{clientValues?.companyId || '-'}</span>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                        <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                            <span className='mr-1 text-bold'>{t('account-management.company-vat')}</span>
                            <span className='account-value'>{clientValues?.vatNumber || '-'}</span>
                        </div>
                    </div>
                </div>

            }
        </div>
    );
};
