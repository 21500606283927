import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import CustomButton from '../../components/button/button';
import { getCardData } from '../../components/plans/data/data';
import { IPlanCard, PlanCard } from '../../components/plans/planCard/planCard';
import { clientContext } from '../../context/clientContext';
import { ReactComponent as SuccessIcon } from '../../static/icons/plans/icon_activate.svg';

export const PaymentStep3: React.FC = () => {

    const { dataClient } = useContext(clientContext);
    const { t } = useTranslation();
    const params: any = useParams();
    const { push } = useHistory();
    const cardData = getCardData(t, params.plan) as IPlanCard;

    if (dataClient && dataClient.support) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center plans-component-container step-2 py-5 px-3 p-lg-5">
                <SuccessIcon height={50} width={50} className="mb-3 mt-5 mb-lg-5 mt-lg-0" />
                <div className="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                    <h2 className="mb-0">{t('payment.step-3.thanks', { clientName: dataClient.firstName })}</h2><br />
                    <h2 className="mt-0">{t('payment.step-3.thanks-message', { planName: params.plan })}</h2><br />
                    <span className='color-clear-blue-grey'>{t('payment.step-3.mail-laius')}</span>
                </div>
                <div className="plans-card-container step-3 mb-5">
                    <PlanCard
                        name={cardData.name}
                        title={cardData.title}
                        laius={cardData.laius}
                        price={cardData.price}
                        plan={"payment"}
                        laius2={t('payment.step-2.laius-2')}
                        customClass="disabled active step-3"
                    />
                </div>
                <CustomButton
                    buttonType='button'
                    onClick={() => push('/account')}
                    buttonText={t('payment.step-3.button-back')}
                />
            </div>
        );
    }
    return null;
};
