import { useContext } from 'react';
import { IPlanCard } from '../../components/plans/planCard/planCard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PlansComponent } from '../../components/plans/plansComponent';
import { getCardData } from '../../components/plans/data/data';
import { clientContext } from '../../context/clientContext';

export const AccountPlans = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const clientStore = useContext(clientContext);

    const handlePlanNavigation = (plan: string) => (_: Event) => {
        push(`/payment/${plan}/step-2`);
    };

    if (!clientStore.dataClient) {
        return null;
    }

    return (
        <PlansComponent
            cardData={
                getCardData(
                    t,
                    null,
                    handlePlanNavigation,
                    clientStore.dataClient.isTrial ? undefined : clientStore.dataClient.support?.toLocaleLowerCase()
                ) as IPlanCard[]
            }
        />
    );
};
