import React, { createContext, useState } from 'react';

const initialState = {
    selected: 0,
    handleSelected: (selected: number) => {},
};

const pricingContext = createContext(initialState);

const PricingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);

    const handleSelected = (selected: number) => {
        setState({
            ...state,
            selected,
        });
    };

    return <pricingContext.Provider value={{ ...state, handleSelected }}>{children}</pricingContext.Provider>;
};

export { PricingProvider, pricingContext };
