import { useContext } from 'react';
import { ACTION_TYPE, dragNDropContext } from './DragNDropContext';

export const useDragNDrop = () => {
    const store = useContext(dragNDropContext);

    const clearDropZone = () => {
        store.dispatch({ type: ACTION_TYPE.CLEAR });
    };

    const addError = (payload?: string) => {
        store.dispatch({ type: ACTION_TYPE.SET_ERROR, payload: payload });
        store.dispatch({ type: ACTION_TYPE.SET_FILE, payload: undefined });
    };

    return {
        ...store.state,
        clearDropZone,
        addError,
    };
};
