import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaginationSvg } from '../../../../static/icons/icon-arrow.svg';

interface IPagination {
    paginationMeta: {
        page: number,
        pagesCount: number
    },
    handlePage: Function,
    handleElementsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void
};

const Pagination: React.FC<IPagination> = ({
    paginationMeta,
    handlePage,
    handleElementsPerPage
}) => {
    const { t } = useTranslation();
    return (
        <div className='pagination d-flex align-items-center'>
            <div className="pagination-per-page">{t("pagination.rowPerPage")}</div>
            <div className='pagination-select'>
                <select name='pages' onChange={handleElementsPerPage}>
                    {[10, 20, 40].map((elementsPerPage, key) => (
                        <option key={key} value={elementsPerPage}>
                            {elementsPerPage}
                        </option>
                    ))}
                </select>
            </div>
            <div className="pagination-meta">
                {paginationMeta?.page} - {paginationMeta?.pagesCount}
            </div>
            <button
                type='button'
                title='Previous page'
                className='button pagination-prev'
                onClick={() => handlePage('-')}
                disabled={paginationMeta?.page === 1 || !paginationMeta}
            >
                <PaginationSvg fill='#BDBDBD' />
            </button>
            <button
                type='button'
                title='Next page'
                className='button pagination-next'
                onClick={() => handlePage('+')}
                disabled={paginationMeta?.page === paginationMeta?.pagesCount}
            >
                <PaginationSvg fill='#BDBDBD' />
            </button>
        </div>
    );
};

export default Pagination;
