import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DragNDrop from '../../../components/dragNDrop/DragNDrop';
import FormData from 'form-data';
import { useDragNDrop } from '../../../components/dragNDrop/useDragNDrop';
import { ReactComponent as PictureSvg } from '../../../static/icons/tasks/icon_picture.svg';
import ZipLogo from '../../../static/icons/tasks/icons8-zip-96.png';
import '../../../static/scss/task-management.scss';
import CustomButton from '../../../components/button/button';
import useWindowDimensions from '../../../hook/useWindowDimensions';
import { useContext } from 'react';
import { clientContext } from '../../../context/clientContext';
import { useHeader } from '../../../hook/useHeader';
import { useAxios } from '../../../services/hook/requestsService';

import { ReactComponent as AgeEstimateSvg } from '../../../static/icons/tasks/age-estimate.svg';
import { ReactComponent as GenderDetectionSvg } from '../../../static/icons/tasks/gender-detection.svg';
import { ReactComponent as MaskDetectionSvg } from '../../../static/icons/tasks/mask-detection.svg';

const PreviewableMimetypes = ['image/jpeg', 'image/png'];
const NotPreviewableMimetypes = ['application/zip', 'application/zip-compressed', 'application/x-zip-compressed'];
const AllowedFormats = [...PreviewableMimetypes, ...NotPreviewableMimetypes];
const TWO_GB = 21_474_836_480;

type PreviewProps = {
    onDrop: (file: File) => void;
    onDragEnter?: (e: React.DragEvent<HTMLElement>) => void;
    onDragLeave?: (e: React.DragEvent<HTMLElement>) => void;
    children: React.ReactNode;
};

const PreviewWrapper: React.FC<PreviewProps> = ({ children, onDrop, onDragEnter, onDragLeave }) => {
    return (
        <DragNDrop onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
            {children}
        </DragNDrop>
    );
};

type PreviewLogoProps = {
    show: boolean;
};

const PreviewLogo: React.FC<PreviewLogoProps> = ({ show = true }) => {
    const dragndropStore = useDragNDrop();

    if (!show) return null;
    const getPreviewPictureColor = () => {
        return dragndropStore.dragging ? '#009BDE' : '#BFC7CE';
    };
    return (
        <div style={{ border: `3px dashed ${getPreviewPictureColor()}` }} className="picture-container d-flex justify-content-center align-items-center">
            <PictureSvg height={80} width={80} fill={getPreviewPictureColor()} />
        </div>
    );
};

type PreviewErrorProps = {
    show: boolean;
    error: any;
};

const PreviewError: React.FC<PreviewErrorProps> = ({ show, error }) => {
    const { t } = useTranslation();
    if (!show) return null;

    const render = () => {
        if (error === 'Size') {
            return (
                <>
                    <p className="color-primary">{t('task-management.error.fileTooLong-title')}</p>
                    <p className="color-primary">{t('task-management.error.fileTooLong')}</p>
                </>
            );
        } else {
            return (
                <>
                    <p className="color-primary">{t('task-management.error.invalid-format-title')}</p>
                    <p className="color-primary">{t('task-management.error.unsupported-foramt-faces-attributes')}</p>
                </>
            );
        }
    };
    return <div className="d-flex flex-column justify-content-center align-items-center text-center">{render()}</div>;
};

type PreviewFileProps = {
    show: boolean;
    file?: File;
};

const PreviewFile: React.FC<PreviewFileProps> = ({ show = false, file = undefined }) => {
    if (!show || !file) return null;

    const render = () => {
        if (PreviewableMimetypes.includes(file.type)) {
            return <img src={URL.createObjectURL(file)} style={{ maxHeight: '100%', maxWidth: '100%' }} alt="faces-attributes-img" />;
        }
        if (NotPreviewableMimetypes.includes(file.type)) {
            return (
                <>
                    <img src={ZipLogo} height="96px" alt="faces-attributes-img" />
                    <p className="color-primary mt-2">{file.name}</p>
                </>
            );
        }
    };
    return <>{render()}</>;
};

export type TaskComponentProps = {
    handleCancel: () => void;
    handleConfirm: (serviceName: string, fileSize: number, callBack: any, withMailSuggestion?: boolean) => void;
    handleError: (e?: any, customError?: string) => void;
    setProgressUpload?: any;
};

export const FacesAttributesTask: React.FC<TaskComponentProps> = ({ handleError, handleConfirm, setProgressUpload, handleCancel }) => {
    const dragndropStore = useDragNDrop();
    const { width } = useWindowDimensions();
    const client = useContext(clientContext);
    const headerData = useHeader();
    const { postData } = useAxios({ isPrivate: true });

    const { t } = useTranslation();

    const dragNDropError = dragndropStore.error;
    const file = dragndropStore.file;

    const onDrop = (file: File) => {
        if (file.size > TWO_GB) {
            dragndropStore.addError('Size');
        }
    };

    const onOpenFile = () => {
        if (dragndropStore.inputRef?.current) {
            dragndropStore.inputRef.current.click();
        }
    };

    const onUploadProgress = (progressEvent: any) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgressUpload(percentCompleted);
    };

    const handlePostData = async (withMail?: boolean) => {
        try {
            const form = new FormData();
            form.append('input_media', file);
            form.append('origin', 'facelytics_app');

            if (withMail) {
                form.append('with_mail', true);
            }
            return await postData(null, 'task-management/faces-attributes', form, { onUploadProgress });
        } catch (e) {
            throw e;
        }
    };

    const onSubmit = () => {
        if (!file) return;
        if (
            client.dataClient?.isTrial &&
            headerData.getTrialState() - file.size < 0 // trial remaining en octet MOINS la taille du fichier en octet
        ) {
            handleError({ response: { data: { message: 'trialLimitation' } } });
            return;
        }
        handleConfirm('Faces attributes', file.size, handlePostData, true);
        return;
    };

    const infoProcessingList = [
        {
            icon: AgeEstimateSvg,
            title: 'task-management.tasks.faces-attributes.age-estimation',
        },
        {
            icon: GenderDetectionSvg,
            title: 'task-management.tasks.faces-attributes.gender-detection',
        },
        {
            icon: MaskDetectionSvg,
            title: 'task-management.tasks.faces-attributes.mask-detection',
        },
    ];

    return (
        <div className="mx-lg-3 task-page">
            <div className="d-flex align-items-center flex-column">
                <div className="w-100 px-lg-4 d-flex row flex-row d-flex align-items-center options-container faces-attributes mb-lg-5 mt-3">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 py-2 py-lg-0 shadow-mobile border-radius-mobile">
                        <PreviewWrapper onDrop={onDrop}>
                            <h3 className="color-primary mb-3 mb-lg-1">{t('task-management.tasks.faces-attributes.select-file')}</h3>
                            <div
                                className={`preview d-flex flex-column align-items-center px-2 pt-2 ${
                                    file ? 'justify-content-end file-selected' : 'justify-content-center'
                                }`}
                            >
                                <div className="preview-image d-flex flex-column align-items-center justify-content-center">
                                    <PreviewLogo show={!dragNDropError && !file} />
                                    <PreviewFile show={!!file && !dragNDropError} file={file} />
                                    <PreviewError show={!!dragNDropError && !file} error={dragNDropError} />
                                </div>
                                <span className={`${!file ? 'color-clear-text' : 'color-white'}`}>
                                    {t('task-management.tasks.faces-attributes.select-file-laius')}
                                </span>
                                <span className={`${!file ? 'color-clear-text' : 'color-white'}`}>{t('task-management.tasks.faces-attributes.max-size')}</span>
                                <CustomButton
                                    classNameType={'main'}
                                    buttonText={
                                        !file
                                            ? t('task-management.tasks.faces-attributes.select-button')
                                            : t('task-management.tasks.faces-attributes.change-button')
                                    }
                                    customClass={`mt-2 mb-2 mb-lg-4 ${!file ? '' : 'btn-white-main'}`}
                                    onClick={onOpenFile}
                                />
                            </div>
                        </PreviewWrapper>
                    </div>
                    <div className={`col-12 col-lg-6 options d-flex ${file ? 'file-selected' : ''} flex-column`}>
                        <div
                            style={{ height: `${width < 992 ? 'auto' : '400px'}` }}
                            className="d-flex w-100 h-100 flex-column justify-content-between align-items-center"
                        >
                            <div className="">
                                <div className="image-processing-info">
                                    {infoProcessingList.map((element) => {
                                        return (
                                            <div>
                                                <element.icon />
                                                <span>{t(element.title)}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                                <i className="image-processing d-flex justify-content-center mb-4 mt-3 ">{t('task-management.tasks.image-processing')}</i>
                                <div className="d-flex justify-content-center align-items-center w-100">
                                    <CustomButton classNameType="mainWhite" buttonText="Cancel" customClass="mr-2" onClick={handleCancel} />
                                    <CustomButton classNameType="main" buttonText="Create" customClass="ml-2" disabled={!file} onClick={onSubmit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
