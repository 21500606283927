import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PaymentHeader } from '../components/paymentHeader/paymentHeader';
import { Client, clientContext } from '../context/clientContext';
import '../static/scss/page.scss';

export const PaymentLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [clientValues, setClientValues] = useState<Client | null>(null);
    const { dataClient, refetchData } = useContext(clientContext);
    const params = useParams();

    useEffect(() => {
        document.body.classList.add('bg-grey');

        if (!clientValues || !dataClient) {
            refetchData();
            setClientValues(dataClient);
        }

        return () => document.body.classList.remove('bg-grey');
    }, [clientValues, dataClient, refetchData]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [params]);

    return (
        <div className="page-payment">
            <PaymentHeader />
            {children}
        </div>
    );
};
