import React, { useContext, useState } from 'react';
import { ACTION_TYPE, dragNDropContext, DragNDropContext } from './DragNDropContext';
import './drag-n-drop.scss';

const dispatchError = (context: DragNDropContext, payload?: string) => {
    context.dispatch({
        type: ACTION_TYPE.SET_ERROR,
        payload: payload,
    });
};

const dispatchDragging = (context: DragNDropContext, payload: boolean) => {
    context.dispatch({
        type: ACTION_TYPE.SET_DRAG,
        payload: payload,
    });
};

const dispatchSetFile = (context: DragNDropContext, payload?: File) => {
    context.dispatch({
        type: ACTION_TYPE.SET_FILE,
        payload: payload,
    });
};

type DragNDropProps = {
    onDrop: (file: File) => void;
    onDragEnter?: (e: React.DragEvent<HTMLElement>) => void;
    onDragLeave?: (e: React.DragEvent<HTMLElement>) => void;
    children: React.ReactChild | React.ReactNode;
    disabled?: boolean;
    id?: string;
};

const DragNDrop: React.FC<DragNDropProps> = ({ onDrop, onDragEnter, onDragLeave, children, id, disabled = false }) => {
    const [dragCounter, setDragCounter] = useState(0);

    if (React.Children.count(children) === 0) {
        throw Error('The DragNDrop component MUST have children');
    }

    const context = useContext(dragNDropContext);

    const overrideEventDefaults = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const verifyFileOnDrop = (e: React.DragEvent<HTMLElement>): File => {
        if (!e.dataTransfer || !e.dataTransfer.files) {
            throw new Error('No file found in drop.');
        }
        if (e.dataTransfer.files.length > 1) {
            throw new Error('Only one file accepted');
        }
        const file = e.dataTransfer.files[0];
        if (!file) {
            throw new Error(`No file detected.`);
        }
        if (!file.type) {
            throw new Error(`File mimetype is unknown.`);
        }
        if (context.state.accepts !== '*' && !context.state.accepts.includes(file.type)) {
            throw new Error(`File mimetype is not accepted.`);
        }
        return file;
    };

    const verifyFileOnClick = (e: React.ChangeEvent<HTMLInputElement>): File => {
        if (!e.target.files) {
            throw new Error('No file found on change event.');
        }
        if (e.target.files.length > 1) {
            throw new Error('Only one file accepted');
        }
        const file = e.target.files[0];
        if (Array.isArray(context.state.accepts) && !context.state.accepts.includes(file.type)) {
            throw new Error(`File mimetype is not accepted.`);
        }
        return file;
    };

    const handleDrop = (e: React.DragEvent<HTMLElement>): void => {
        overrideEventDefaults(e);
        let file: File;
        try {
            file = verifyFileOnDrop(e);
        } catch (error: any) {
            dispatchError(context, error.message);
            dispatchDragging(context, false);
            dispatchSetFile(context, undefined);
            e.dataTransfer.clearData();
            return;
        }
        dispatchSetFile(context, file);
        dispatchDragging(context, false);
        dispatchError(context, undefined);
        e.dataTransfer.clearData();
        onDrop(file);
    };

    const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
        overrideEventDefaults(e);
        setDragCounter(dragCounter + 1);
        dispatchDragging(context, true);
        if (onDragEnter) {
            return onDragEnter(e);
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
        overrideEventDefaults(e);
        setDragCounter(dragCounter - 1);
        if (dragCounter === 1) {
            dispatchDragging(context, false);
            if (onDragLeave) {
                return onDragLeave(e);
            }
        }
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let file: File;
        try {
            file = verifyFileOnClick(e);
        } catch (error: any) {
            dispatchError(context, error.message);
            dispatchDragging(context, false);
            dispatchSetFile(context, undefined);
            return;
        }
        dispatchError(context, undefined);
        dispatchSetFile(context, file);
        dispatchDragging(context, false);

        return onDrop(file);
    };
    return (
        <>
            <input
                ref={context.state.inputRef}
                type="file"
                id={`file-${id}`}
                accept={`${context.state.accepts}`}
                onChange={onChangeInput}
                className="drag-n-drop__input"
                disabled={disabled}
            />
            <label
                id={id}
                htmlFor={`file-${id}`}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={overrideEventDefaults}
                className="drag-n-drop__label"
            >
                {children}
            </label>
        </>
    );
};

export default DragNDrop;
