import React from "react";
import { useTranslation } from "react-i18next";
import { randomKeyGen } from "../../../services/tools";
import { getPlanData } from "../data/data";
import { PlanTab } from "../table/planTab";

const TablePricing: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className='d-flex flex-column justify-content-center align-items-center w-100 mb-4'>
      {getPlanData("comparative", t).map((planData: any) => {
        return <PlanTab key={randomKeyGen()} {...planData} page={"account"} />;
      })}
    </section>
  );
};

export default TablePricing;
