import React, { createContext, useState } from 'react';
import { useAxios } from '../services/hook/requestsService';
import { Client } from './clientContext';
import { Service } from './servicesContext';

const sortEnums = {
    DATE: 'date',
    FILE_TYPE: 'type',
    SERVICE_NAME: 'serviceName',
    FILE_SIZE: 'size',
    TASK_STATUS: 'taskStatus',
};

export enum ArchiveJobStatus {
    SUCCESS = 'SUCCESS',
    SUCESS_WITH_ERRORS = 'SUCCESS WITH ERRORS',
    ERRORED = 'ERRORED',
}

export enum HistoryCallStatus {
    SUCCEEDED = 'Succeeded',
    SENT = 'Sent',
    DELETED = 'Deleted',
    NONE = 'None',
    STARTED = 'Started',
    FAILED = 'Failed',
}

export type ArchiveJob = {
    uuid: string;
    archive_url: string;
    created_at: string;
    custom_job_id: string;
    files_counter: number;
    process_report: string | null;
    status: ArchiveJobStatus | null;
    updated_at: string;
};

export type HistoryCall = {
    client: Client;
    date: Date;
    filename: string;
    ip_address: string;
    service: Service;
    size: number;
    taskId: string;
    taskStatus: string;
    type: string;
    uuid: string;
    filenameAlias?: string;
    archive_job?: ArchiveJob;
    job_id: string;
    status: ArchiveJobStatus;
    service_search_key: string;
    file_type: 'PICTURE' | 'VIDEO' | 'BULK';
    fileurl: string;
    parameters: any;
};

export type HistoryCallRequestState = {
    page: number;
    elementsPerPage: number;
    sortBy: string;
    searchKey: string | null;
    fileType: 'PICTURE' | 'VIDEO' | null;
    status: string | null;
    dateEnd: string | null;
    dateStart: string | null;
};

export type HistoryCallResponseState = {
    elements: HistoryCall[] | [];
    elementsPerPage: string;
    page: string;
    totalElements: number;
    totalPage: number;
};

export type HistoryCallState = {
    data: HistoryCallResponseState | null;
    isLoading: boolean;
    isError: boolean;
    filterState: any;
    sortState: { sortBy: string; sortOrder: 'ASC' | 'DESC' };
    getAllHistoryCall: () => Promise<void>;
    handleSort: (e: any) => void;
    handlePagination: (string: '+' | '-') => void;
    handleElementsPerPage: (e: any) => void;
    handleFilter: (keyName: string, select: any) => void;
    handleClear: () => void;
};

const initialState: HistoryCallState = {
    data: null,
    isLoading: true,
    isError: false,
    sortState: { sortBy: sortEnums.DATE, sortOrder: 'DESC' },
    getAllHistoryCall: async () => {
        return;
    },
    handleSort: () => 1,
    handleElementsPerPage: () => 1,
    handlePagination: () => 1,
    handleFilter: () => 1,
    handleClear: () => 1,
    filterState: {},
};

const historyCallContext = createContext<HistoryCallState>(initialState);

const HistoryCallProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const { getData } = useAxios({ isPrivate: true });
    const [paginationState, handlePaginationState] = useState({
        page: 1,
        elementsPerPage: 10,
    });
    const [sortState, setSortState] = useState<{ sortBy: string; sortOrder: 'ASC' | 'DESC' }>({ sortBy: sortEnums.DATE, sortOrder: 'DESC' });
    const [filterState, setFilters] = useState({
        searchKey: null,
        fileType: null,
        taskStatus: null,
        dateEnd: '',
        dateStart: '',
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setError] = useState<boolean>(false);

    const getAllHistoryCall = async () => {
        setIsLoading(true);

        const filterTmp = {};
        Object.keys(filterState).forEach((key) => {
            //@ts-ignore
            if (filterState[key]) {
                if (key === 'dateStart' || key === 'dateEnd') {
                    //@ts-ignore
                    filterTmp[key] = filterState[key];
                } else {
                    //@ts-ignore
                    filterTmp[key] = filterState[key].value;
                }
            }
        });
        (filterTmp as any)['searchKey'] = ['SRV_FACE'];
        const params = {
            ...paginationState,
            ...filterTmp,
            ...sortState,
        };
        const { data, error } = await getData(null, 'history-call', { params });
        if (error) {
            setError(true);
        }
        if (data) {
            setState({ ...state, data });
        }
        setIsLoading(false);
    };

    const handleSort = (e: any) => {
        setSortState({
            sortBy: e.target.name,
            sortOrder: sortState.sortOrder === 'ASC' ? 'DESC' : 'ASC',
        });
    };

    const handlePagination = (action: '+' | '-') => {
        handlePaginationState({ ...paginationState, page: action === '+' ? paginationState?.page + 1 : paginationState?.page - 1 });
    };

    const handleElementsPerPage = (e: any) => {
        handlePaginationState({ ...paginationState, elementsPerPage: parseInt(e.target.value) });
    };

    const handleClear = () => {
        setFilters({
            searchKey: null,
            fileType: null,
            taskStatus: null,
            dateEnd: '',
            dateStart: '',
        });
    };

    const handleFilter = (keyName: string, select: any) => {
        if (keyName === 'dateStart' || keyName === 'dateEnd') {
            setFilters({
                ...filterState,
                [keyName]: select.target.value,
            });
        } else {
            setFilters({
                ...filterState,
                [keyName]: select.value === undefined ? null : select,
            });
        }
    };

    return (
        <historyCallContext.Provider
            value={{
                ...state,
                isLoading,
                isError,
                filterState,
                sortState,
                getAllHistoryCall,
                handleSort,
                handlePagination,
                handleElementsPerPage,
                handleFilter,
                handleClear,
            }}
        >
            {children}
        </historyCallContext.Provider>
    );
};

export { historyCallContext, HistoryCallProvider };
