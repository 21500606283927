import { useContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CustomButton from '../../components/button/button';
import Modal, { IModal } from '../../components/modal/Modal';
import { customCalendarContext } from '../../context/calendarContext';
import { servicesManagementContext } from '../../context/ServicesManagementContext';
import { convertSize } from '../../services/tools';
import useWindowDimensions from '../../hook/useWindowDimensions';
import GlobalServicesData from './globalServicesData';

// Icon
import { ReactComponent as IconChevron } from '../../static/icons/icon-chevron-mobile.svg';

type Params = {
    service: string;
};

const ServiceLayout = () => {
    const params = useParams() as Params;
    const serviceLocation = params?.service;
    const data = useContext(servicesManagementContext);
    const { t } = useTranslation();
    const [modalState, setModalState] = useState({} as IModal);
    const calendarContext = useContext(customCalendarContext);

    const [expanded, setExpanded] = useState<Record<number, boolean>>({}); // => {index: boolean}
    const { width } = useWindowDimensions();

    const handleExpanded = (id: number) => {
        if (width > 992) {
            return null;
        } else {
            setExpanded((prevState) => ({
                ...prevState,
                [id]: !prevState[id],
            }));
        }
    };

    const handleOpenModal = () => {
        setModalState({
            ...modalState,
            show: true,
            showCancel: false,
            onConfirm: () => setModalState({ ...modalState, show: false }),
            message: t('services-management.service.modal-modification-1'),
            confirmBtnText: t('common.text.close'),
        });
    };

    return (
        <div>
            {/* TODO: Laisser en commentaire pour le moment */}
            {/* <div className='d-none d-lg-flex d-xl-flex w-100 justify-content-end mb-3'>
        <CustomButton
          classNameType='mainInverted'
          buttonText={t('services-management.service.modification')}
          onClick={handleOpenModal}
        />
      </div> */}
            <GlobalServicesData />
            {serviceLocation &&
                data?.allClientServicesData &&
                Object.keys(data?.allClientServicesData[serviceLocation])?.map((service, index) => {
                    const serviceData = data?.allClientServicesData[serviceLocation][service];
                    //format consumption data
                    let serviceConsumption: any = {
                        totalSize: 0,
                        totalCalls: 0,
                    };
                    if (serviceLocation === 'identity') {
                        data?.consumptionState?.data?.totalCountsPerPeriod
                            ?.filter((data: any) => {
                                if (data.searchKey === 'SRV_IDENTITY' && service === 'SEARCH' && data.typeOfCall === 'SEARCH') {
                                    return true;
                                } else if (
                                    data.searchKey === 'SRV_IDENTITY' &&
                                    service === 'UPSERT' &&
                                    (data.typeOfCall === 'CREATE' || data.typeOfCall === 'UPDATE')
                                ) {
                                    return true;
                                } else if (data.searchKey === 'SRV_IDENTITY' && service === 'RECOGNIZE' && data.typeOfCall === 'RECOGNITION') {
                                    return true;
                                } else {
                                    return false;
                                }
                            })
                            ?.forEach((elem: any, idx: number) => {
                                serviceConsumption = {
                                    totalSize: serviceConsumption.totalSize + parseInt(elem.size),
                                    totalCalls: serviceConsumption.totalCalls + parseInt(elem.totalCount),
                                };
                                if (service === 'UPSERT') {
                                    serviceConsumption = {
                                        ...serviceConsumption,
                                        identity: data.consumptionState.identityData,
                                    };
                                }
                            });
                    } else {
                        data?.consumptionState?.data?.totalCountsPerPeriod
                            ?.filter((data: any) => data.searchKey === service)
                            ?.forEach((elem: any) => {
                                serviceConsumption = {
                                    totalSize: serviceConsumption.totalSize + parseInt(elem.size),
                                    totalCalls: serviceConsumption.totalCalls + parseInt(elem.totalCount),
                                };
                            });
                    }
                    return (
                        <div className="middle-container mb-4 service-card" key={index} data-expanded={expanded[index]}>
                            <div
                                className={`d-flex align-items-center justify-content-between justify-content-lg-start${
                                    expanded[index] && width < 992 ? ' mb-3' : ' mb-0'
                                } mb-lg-1`}
                                onClick={() => handleExpanded(index)}
                            >
                                <h2 className="mb-0 me-lg-4 mb-lg-3">
                                    {serviceData.title === 'RECOGNIZE'
                                        ? t('services-management.service.identity.title-recognize')
                                        : serviceData.title === 'UPSERT'
                                        ? t('services-management.service.identity.title-creation')
                                        : serviceData.title === 'SEARCH'
                                        ? t('services-management.service.identity.title-search')
                                        : serviceData.title}
                                </h2>
                                {
                                    <div
                                        className={`service-label-${
                                            serviceData?.isActive ? 'active' : 'inactive'
                                        } d-flex align-items-center activation-container ${
                                            serviceData?.isActive ? 'active' : 'inactive'
                                        } ms-auto mb-lg-3 ms-lg-4`}
                                    >
                                        <label>
                                            {serviceData?.isActive ? t('services-management.service.activated') : t('services-management.service.deactivated')}
                                        </label>
                                        {serviceData?.isActive ? (
                                            <span className="ms-1 ms-lg-3">{serviceData?.isActive ? moment(serviceData?.start).format('DD/MM/YYYY') : ''}</span>
                                        ) : null}
                                    </div>
                                }

                                {width < 992 && (
                                    <button type="button" className={`button btn-collapse${expanded[index] ? ' expanded' : ''} ms-3`}>
                                        <IconChevron fill={expanded[index] ? 'white' : '#6B7897'} />
                                    </button>
                                )}
                            </div>
                            <div className={`collapse-${expanded[index] ? 'visible' : 'hidden'}`} aria-hidden={!expanded[index]}>
                                <div className="row flex-row mb-lg-3">
                                    {/* ACTIVATION DATE */}
                                    <div className="col-12 col-lg-4 mb-2 mb-lg-0">
                                        <div className="service-label-date d-flex align-items-center justify-content-between justify-content-lg-start">
                                            <label className="me-lg-4">
                                                {serviceData.typeOfService === 'SDK' || serviceData.typeOfService === 'PREMISE'
                                                    ? t('services-management.service.starting-date')
                                                    : t('services-management.service.activation-date')}
                                            </label>
                                            <span>{serviceData?.isActive ? moment(serviceData?.start).format('DD/MM/YYYY') : '-'}</span>
                                        </div>
                                    </div>
                                    {/* TOTAL CALLS API || ENDING DATE */}
                                    <div className="col-12 col-lg-4 mb-2 mb-lg-0">
                                        <div
                                            className={`service-label-${
                                                serviceData.typeOfService === 'SDK' || serviceData.typeOfService === 'PREMISE' ? 'ending-date' : 'total-call'
                                            } d-flex align-items-center justify-content-between justify-content-lg-start`}
                                        >
                                            <label className="me-lg-4">
                                                {serviceData.typeOfService === 'SDK' || serviceData.typeOfService === 'PREMISE'
                                                    ? t('services-management.service.ending-date')
                                                    : t('services-management.service.total-call')}
                                            </label>
                                            <span>
                                                {serviceData.typeOfService === 'API'
                                                    ? serviceConsumption?.totalCalls || '-'
                                                    : serviceData?.isActive
                                                    ? serviceData?.end
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                    {/* TOTAL CALLS CONSUMPTION || SDK TYPE || OP SETUP */}
                                    <div className="col-12 col-lg-4 mb-2 mb-lg-0">
                                        <div
                                            className={`service-label-${
                                                serviceData.typeOfService === 'SDK'
                                                    ? 'sdk-type'
                                                    : serviceData.typeOfService === 'PREMISE'
                                                    ? 'setup'
                                                    : 'total-call'
                                            } d-flex align-items-center justify-content-between justify-content-lg-start`}
                                        >
                                            <label className="me-lg-4">
                                                {serviceData.typeOfService === 'API'
                                                    ? t('services-management.service.total-consumption')
                                                    : serviceData.typeOfService === 'SDK'
                                                    ? t('services-management.service.sdk-type')
                                                    : t('services-management.service.setup')}
                                            </label>
                                            <span>
                                                {serviceData.typeOfService === 'API'
                                                    ? serviceConsumption?.totalSize
                                                        ? convertSize(serviceConsumption?.totalSize)
                                                        : '-'
                                                    : serviceData.typeOfService === 'SDK'
                                                    ? serviceData?.sdkType
                                                        ? t(`services-management.service.support-licence.${serviceData?.sdkType}`)
                                                        : '-'
                                                    : serviceData?.OPSetup
                                                    ? t(`services-management.service.support-licence.${serviceData?.OPSetup}`)
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex-row">
                                    {/* API SUPPORT TYPE || SDK SUPPORT TYPE || OP LICENSE TYPE */}
                                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                                        <div
                                            className={`service-label-${
                                                serviceData.typeOfService === 'SDK' || serviceData.typeOfService === 'API' ? 'support-type' : 'licence-type'
                                            } d-flex align-items-center justify-content-between justify-content-lg-start`}
                                        >
                                            <label className="me-lg-4">
                                                {serviceData.typeOfService === 'API' || serviceData.typeOfService === 'SDK'
                                                    ? t('services-management.service.support-type')
                                                    : t('services-management.service.license-type')}
                                            </label>
                                            <span>
                                                {serviceData.typeOfService === 'API' || serviceData.typeOfService === 'SDK'
                                                    ? serviceData?.support
                                                        ? t(`services-management.service.support-licence.${serviceData?.support}`)
                                                        : '-'
                                                    : serviceData?.OPLicenceType
                                                    ? t(`services-management.service.support-licence.${serviceData?.OPLicenceType}`)
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                    {/*OP AUTHORIZED SERVICES */}
                                    {serviceData.typeOfService === 'PREMISE' && (
                                        <>
                                            <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                                                <div
                                                    className={`service-label-${
                                                        serviceData.typeOfService === 'PREMISE' ? 'auth-services' : ''
                                                    } d-flex align-items-center justify-content-between justify-content-lg-start`}
                                                >
                                                    <label className="me-lg-4">
                                                        {serviceData.typeOfService === 'PREMISE' ? t('services-management.service.authorized-services') : null}
                                                    </label>
                                                    <span>
                                                        {serviceData.typeOfService === 'PREMISE'
                                                            ? serviceData?.OPLicenceType
                                                                ? serviceData?.services?.map((service: string) =>
                                                                      t(`services-management.service.authorized-services-enum.${service}`)
                                                                  )
                                                                : '-'
                                                            : null}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4"></div>
                                        </>
                                    )}
                                    {/* IDENTITY SEARCH/UPDATE VECTORS AND MODELS */}
                                    {serviceData.title === 'UPSERT' && (
                                        <>
                                            <div className="col-12 col-lg-4">
                                                <div className={`service-label-total-identity d-flex align-items-center`}>
                                                    <label className="me-lg-4">Models</label>
                                                    <span>{serviceConsumption?.identity?.totalCounts?.totalIdentities || '-'}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className={`service-label-total-identity d-flex align-items-center`}>
                                                    <label className="me-lg-4">Vectors</label>
                                                    <span>{serviceConsumption?.identity?.totalCounts?.totalVectors || '-'}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="d-lg-flex d-xl-flex w-100 justify-content-center justify-content-lg-end">
                                    <CustomButton
                                        classNameType="mainWhite"
                                        buttonText={t('services-management.service.details')}
                                        onClick={() =>
                                            calendarContext.handleShow(
                                                true,
                                                'apiCallsPerYear',
                                                serviceLocation,
                                                serviceLocation === 'identity' ? service : undefined
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            <Modal {...modalState} />
        </div>
    );
};

export default ServiceLayout;
