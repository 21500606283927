import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import CustomButton from '../../../components/button/button';
import Input from '../../../components/input/Input';
import { useAxios } from '../../../services/hook/requestsService';
import { Cookies, withCookies } from 'react-cookie';
import moment from 'moment';
import RoundedLoader from '../../../components/loader/RoundedLoader';
import ErrorComponent from '../../../components/error/errorComponent';
import { useQuery } from '../../../services/hook/useQuery';
import {ReactComponent as LogoWisSvg} from "../../../static/images/logo-WIS.svg";

const initialValues = {
  email: '',
  password: ''
};

const Login: React.FC<any> = ({ cookies }: { cookies: Cookies }) => {
  const { t } = useTranslation();
  const { postData, error: isError, loading } = useAxios({ isPrivate: false });
  const [error, setError] = useState(null);
  const { push } = useHistory();
  const query = useQuery();
  const redirection = query.get('redirect');

  const isFormInValid = (values: any): boolean => {
    return values.email.length <= 3 || values.password.length <= 3;
  };

  const submitLogin = async ({ email, password }: { email: string, password: string }) => {
    try {

      const { data }: any = await postData(null, 'login', { email, password });
      if (data) {

        cookies.set('Auth_manager', JSON.stringify({
          token: data.token,
          refreshToken: data.refreshToken,
          email: data.email
        }), { expires: moment().add(data.expireTime, 's').toDate(), path: '/' });
        if (redirection) {
          push(redirection);
        } else {
          push('/task-manager');
        }
      }
    } catch (e: any) {
      setError(e);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitLogin}>
      {({ values, handleChange }) => {
        return (
          <Form className='d-flex flex-column justify-content-between'>
            <div className='mb-4'>
              <Input
                isInvalid={isError}
                id='login'
                name='email'
                icon='login'
                value={values.email}
                placeholder={t('login.emailPlaceholder')}
                isRequired
                onChange={handleChange as any}
                inputType='text'
                inputClassName='rounded'
                labelTranslation={t('login.emailLabel')}
              />
              <Input
                isInvalid={isError}
                id='password'
                icon='password'
                name='password'
                value={values.password}
                placeholder={t('login.passwordPlaceholder')}
                isRequired
                onChange={handleChange as any}
                inputType='password'
                inputClassName='rounded'
                labelTranslation={t('login.passwordLabel')}
                maskable={true}
              />

            </div>
            {
              loading
                ?
                <div className='mb-4 d-flex justify-content-center'>
                  <RoundedLoader isLoading={loading} />
                </div>
                :
                <div className='mb-4 d-flex flex-column align-items-center justify-content-center'>
                  <CustomButton customClass="mb-3" disabled={isFormInValid(values)} buttonText={t('login.signIn')} classNameType='main' />
                  <Link id='forgotten-password' to='/forgotten-password'>{t('login.forgotten-password')}</Link>
                </div>
            }
            {
              (isError && error) &&
              <ErrorComponent error={error} customError={t('login.invalidCredentials')} />
            }
          </Form>
        )
      }}
    </Formik>
  );
};

export default withCookies(Login);