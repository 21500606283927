import React from 'react';
import { RecoilRoot } from 'recoil';
import Routes from './routing/router';

const App = () => {
  return (
    <RecoilRoot>
      <React.Suspense fallback={<></>}>
        <div className="App">
          <Routes />
        </div>
      </React.Suspense>
    </RecoilRoot>
  );
};

export default App;
