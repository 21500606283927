import React, { useState, useEffect, useContext } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../services/hook/requestsService';
import CustomSelect from '../select/CustomSelect';
import { fakeData as graphOptions } from './graphData';
import moment from 'moment';
import { formatTotalApiCallsAndConsumptionGraph } from '../../services/dashboardService';
import { clientContext } from '../../context/clientContext';
import useWindowDimensions from '../../hook/useWindowDimensions';

type Option = {
    label: string;
    value: string;
};
interface ITotalGraph {
    service: string;
    selected: Option;
    options: Option[];
    handleSelect: (selected: unknown) => void;
}

const TotalDataGraphs: React.FC<ITotalGraph> = ({ service, selected, handleSelect, options }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [graphSeries, setGraphSeries] = useState<any | null>(graphOptions);
    const clientState = useContext(clientContext);
    const { t } = useTranslation();
    const { getData } = useAxios({ isPrivate: true });
    const { width } = useWindowDimensions();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const perYearData = await getData(null, 'history-call/consumption', {
                params: {
                    service: service !== 'all' ? service : undefined,
                    period: 'month',
                    dateStart: selected.value,
                    dateEnd: moment(selected.value).endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    isTrial: clientState.dataClient?.isTrial,
                },
            });
            const { yearApiCalls, yearConsumption } = formatTotalApiCallsAndConsumptionGraph(perYearData.data?.totalCountsPerPeriod || [], selected.value);
            const graphOptionsTmp = { ...graphOptions };

            graphOptionsTmp.series[0].data = yearApiCalls;
            graphOptionsTmp.series[1].data = yearConsumption;

            setGraphSeries(graphOptionsTmp);

            setIsLoading(false);
        })();
    }, [selected, service]);

    return (
        <div className="h-100 w-100">
            <div className="d-flex justify-content-between mx-4 mb-3">
                <div className="d-none d-lg-flex align-items-center">
                    <h2 className="mb-0 mr-1">{t('dashboard.total-per-year-graph.title')}</h2>
                </div>
                <div style={{ minWidth: '20%' }} className="w-50-mobile ms-auto">
                    <CustomSelect onChange={handleSelect} options={options} value={selected} />
                </div>
            </div>
            {isLoading ? null : (
                <Chart
                    //@ts-ignore
                    options={graphOptions.options}
                    series={graphSeries?.series}
                    type="bar"
                    width={width < 992 ? '100%' : '105%'}
                    height="85%"
                />
            )}
        </div>
    );
};

export default TotalDataGraphs;
