import React, { useContext, useEffect, useState } from 'react';
import { clientContext, Client } from '../../context/clientContext';
import '../../static/scss/account.scss';
import { servicesContext } from '../../context/servicesContext';
import { AccountInformation } from './components/accountInformation';
import { ClientInformation } from './components/clientInformation';
import { PaymentInformation } from './components/paymentInformation';

const MyAccount: React.FC = () => {
    const [clientValues, setClientValues] = useState<Client | null>(null);
    const { dataClient, refetchData, error } = useContext(clientContext);
    const { getData: getServiceData } = useContext(servicesContext);

    useEffect(() => {
        if (!clientValues || !dataClient || !error) {
            refetchData();
            setClientValues(dataClient);
            getServiceData();
        }
    }, [dataClient]);

    return (
        <>
            <AccountInformation clientValues={clientValues} />
            <div className="mb-3" />
            <ClientInformation clientValues={clientValues} />
            <div className="mb-3" />
            {!clientValues?.isTrial && <PaymentInformation clientValues={clientValues} />}
        </>
    );
};

export default MyAccount;
