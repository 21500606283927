import React from 'react';
import Header from '../components/header/Header';
import Sidebar from '../components/sidebar/Sidebar';
import useWindowDimensions from '../hook/useWindowDimensions';
import '../static/scss/page.scss';

const PageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { width } = useWindowDimensions();

    return (
        <>
            <Sidebar />
            <Header />
            <div className={`page-container py-3 ${width >= 991 ? 'px-4' : 'task-container'}`}>
                <div className="container-fluid">{children}</div>
            </div>
        </>
    );
};

export default PageLayout;
