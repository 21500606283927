import { useContext } from 'react';
import { TFunction } from 'i18next';
import { IPlanTab, IContent } from '../table/planTab';
import { ReactComponent as ActiveCheck } from '../../../static/icons/plans/icon_input_check.svg';
import { ReactComponent as UnavailableCross } from '../../../static/icons/plans/icon_table_cross.svg';
import { IPlanCard } from '../planCard/planCard';
import CustomButton from '../../button/button';
import { pricingContext } from '../../../context/pricingContext';
import { clientContext } from '../../../context/clientContext';

const plans = ['BASIC', 'STANDARD', 'PREMIUM'];

const initHtmlElement = ({ content, className = 'text-center', title, ...rest }: IContent): IContent => ({
    content,
    className,
    title,
    ...rest,
});

const getClasses = (selected: number, index: number, status: number, support: string | undefined) => {
    const windowSize = window.innerWidth;

    const classes = ['text-center'];
    if (index === selected || (plans.indexOf(support as string) === index && windowSize > 992)) {
        classes.push('active');
    }
    if (plans.indexOf(support as string) === index && windowSize > 992) {
        classes.push('disabled');
    }
    return classes.join(' ');
};

const _getIconCells = (data: Array<0 | 1 | 2>): IContent[] => {
    const { selected } = useContext(pricingContext);
    const { dataClient } = useContext(clientContext);

    const iconsStatus = {
        0: <ActiveCheck />,
        1: <ActiveCheck />,
        2: <UnavailableCross />,
    };
    return data.map((status, index) =>
        initHtmlElement({
            content: iconsStatus[status],
            className: getClasses(selected, index, status, dataClient?.support),
        })
    );
};

const pricingData = (t: TFunction): IPlanTab => ({
    planTableHeads: [
        initHtmlElement({
            content: (
                <>
                    {t('plan.tab-consumption-pricing.th-consumption')} <span>{t('plan.tab-consumption-pricing.th-consumption-pm') as string}</span>
                </>
            ),
            style: {
                width: '26%',
            },
            className: 'text-center',
        }),
        initHtmlElement({
            content: (
                <>
                    {t('plan.tab-consumption-pricing.th-photos')} <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                </>
            ),
            style: {
                width: '37%',
            },
        }),
        initHtmlElement({
            content: (
                <>
                    {t('plan.tab-consumption-pricing.th-videos')} <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                </>
            ),
            style: {
                width: '37%',
            },
        }),
    ],
    planTableData: [
        {
            thData: initHtmlElement({
                content: t('plan.tab-consumption-pricing.0Mb-50Mb'),
                style: {
                    width: '37%',
                },
                className: 'text-center',
            }),
            tdData: [
                initHtmlElement({
                    content: '0.1€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-photos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
                initHtmlElement({
                    content: '0.02€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-videos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
            ],
        },
        {
            thData: initHtmlElement({
                content: t('plan.tab-consumption-pricing.51Mb-1Gb'),
                className: 'text-center',
            }),
            tdData: [
                initHtmlElement({
                    content: '0.05€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-photos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
                initHtmlElement({
                    content: '0.02€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-videos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
            ],
        },
        {
            thData: initHtmlElement({
                className: 'text-center',
                content: t('plan.tab-consumption-pricing.1Gb-10Gb'),
            }),
            tdData: [
                initHtmlElement({
                    content: '0.03€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-photos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
                initHtmlElement({
                    content: '0.015€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-videos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
            ],
        },
        {
            thData: initHtmlElement({
                className: 'text-center',
                content: t('plan.tab-consumption-pricing.10Gb-100Gb'),
            }),
            tdData: [
                initHtmlElement({
                    content: '0.02€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-photos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
                initHtmlElement({
                    content: '0.015€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-videos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
            ],
        },
        {
            thData: initHtmlElement({
                className: 'text-center',
                content: t('plan.tab-consumption-pricing.10Gb-100Gb'),
            }),
            tdData: [
                initHtmlElement({
                    content: '0.02€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-photos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
                initHtmlElement({
                    content: '0.01€',
                    title: (
                        <div className="td-mobile-title">
                            {t('plan.tab-consumption-pricing.th-videos') as string}{' '}
                            <span>{t('plan.tab-consumption-pricing.th-devices-currency') as string}</span>
                        </div>
                    ),
                }),
            ],
        },
    ],
});

const comparativeData = (t: TFunction) => {
    const tableHeads = (traductions: string) => [
        initHtmlElement({
            content: t(traductions),
            className: 'text-left',
            style: {
                width: '12.5%',
            },
        }),
        ...[0, 1, 2].map((_) => initHtmlElement({ content: null, style: { width: '25%' } })),
    ];

    return [
        {
            planTableHeads: tableHeads('plan.tab-compared-feature.th-media'),
            planTableData: [
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.pictures'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.videos'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.zip'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
            ],
        },
        {
            planTableHeads: tableHeads('plan.tab-compared-feature.th-resolution'),
            planTableData: [
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.hd'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.4k'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.8k'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.over8k'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
            ],
        },
        {
            planTableHeads: tableHeads('plan.tab-compared-feature.th-file-size'),
            planTableData: [
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.<100Mb'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.101Mb-500Mb'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.501Mb-1Gb'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.1Bg-2Gb'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
            ],
        },
        {
            planTableHeads: tableHeads('plan.tab-compared-feature.th-api-accessibility'),
            planTableData: [
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.anonymization-faces'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.anonymization-plates'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.face-attributes-gender'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.face-attributes-age'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.face-attributes-counting'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.vehicles-pedestrians'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.watermark'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.faces-recognition'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.traffic-analysis'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.image-orientation'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.soiling'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
            ],
        },
        {
            planTableHeads: tableHeads('plan.tab-compared-feature.support'),
            planTableData: [
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.online-doc'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.updates'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.issue-reporting'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([0, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.response-guarantee'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([2, 0, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.hotline'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([2, 2, 0]),
                },
                {
                    thData: initHtmlElement({
                        content: t('plan.tab-compared-feature.assistance'),
                        className: 'text-left',
                    }),
                    tdData: _getIconCells([2, 2, 0]),
                },
            ],
        },
    ];
};

export const getPlanData = (key: 'pricing' | 'comparative', t: TFunction) => (key === 'pricing' ? [pricingData(t)] : comparativeData(t));

export const getCardData = (t: TFunction, select?: string | null, handlePlanNavigation?: Function, cantNavigateTo?: string) => {
    const cardData: IPlanCard[] = [
        {
            name: 'basic',
            title: t('plan.plan-card.basic.title'),
            price: 0,
            laius: t('plan.plan-card.laius'),
            options:
                //@ts-ignore // C MOCHE MAIS FLEMME DE TYPER LA
                t<string[]>('plan.plan-card.basic.options', {
                    returnObjects: true,
                }).map((props) => props),
            button:
                handlePlanNavigation && cantNavigateTo !== 'basic' ? (
                    <CustomButton classNameType="mainWhite" buttonText={t('plan.update-plan') as string} onClick={handlePlanNavigation('basic')} />
                ) : undefined,
        },
        {
            name: 'standard',
            title: t('plan.plan-card.standard.title'),
            price: 15,
            laius: t('plan.plan-card.laius'),
            options:
                //@ts-ignore // C MOCHE MAIS FLEMME DE TYPER LA
                t<string[]>('plan.plan-card.standard.options', {
                    returnObjects: true,
                }).map((props) => props),
            button:
                handlePlanNavigation && cantNavigateTo !== 'standard' ? (
                    <CustomButton classNameType="mainWhite" buttonText={t('plan.update-plan') as string} onClick={handlePlanNavigation('standard')} />
                ) : undefined,
        },
        {
            name: 'premium',
            title: t('plan.plan-card.premium.title'),
            price: 50,
            laius: t('plan.plan-card.laius'),
            options:
                //@ts-ignore // C MOCHE MAIS FLEMME DE TYPER LA
                t<string[]>('plan.plan-card.premium.options', {
                    returnObjects: true,
                }).map((props) => props),
            button:
                handlePlanNavigation && cantNavigateTo !== 'premium' ? (
                    <CustomButton classNameType="mainWhite" buttonText={t('plan.update-plan') as string} onClick={handlePlanNavigation('premium')} />
                ) : undefined,
        },
    ];

    if (!select) {
        return cardData;
    } else {
        return cardData.find((card) => card.name === select);
    }
};
