import React from "react";
import { useTranslation } from "react-i18next";
import { IPlanCard } from "./planCard/planCard";
import StickyHeader from "./stickyHeader/stickyHeader";
import TablePricing from "./tablePricing/tablePricing";
import { PricingProvider } from "../../context/pricingContext";
import useWindowDimensions from "../../hook/useWindowDimensions";
import { useHistory } from "react-router";

// Icon
import { ReactComponent as IconBackSvg } from "../../static/icons/icon-back-mobile.svg";
import { ReactComponent as IconPlus } from "../../static/icons/plans/icon_plus.svg";

// Style
import "./plans.scss";

type TPricingList = {
  title: string;
  price?: string;
  fileSize?: string;
  separator?: boolean;
};

interface IPlansComponent {
  cardData: IPlanCard[];
  page?: "account" | "payment";
}

export const PlansComponent: React.FC<IPlansComponent> = ({
  cardData,
  page = "account",
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { push } = useHistory();

  const pricingArray: TPricingList[] = [
    {
      title: "Image",
      price: "0.05€",
      fileSize: "/MB",
    }
  ];

  return (
    <PricingProvider>
      <div className='d-flex flex-column align-items-center justify-content-center plans-component-container pt-3'>
        <div
          className='d-flex d-lg-none d-xl-none step-back me-auto'
          aria-hidden={width > 992}
          onClick={() => push("/account")}
        >
          <IconBackSvg fill='#6b7897' />
          Account
        </div>
        <section className='d-flex flex-column align-items-center justify-content-center text-center w-75 w-100-mobile mb-4'>
          <h2 className='pricing-title'>Pay only for the data you process!</h2>

          <ul className='pricing-list'>
            {pricingArray.map((element, index) => {
              return (
                <React.Fragment key={index}>
                  {!element.separator ? (
                    <li className='pricing-list--element'>
                      <h5 className='pricing-list--title'>{element.title}</h5>
                      <p className='pricing-list--text'>
                        <span>{element.price}</span>
                        {element.fileSize}
                      </p>
                    </li>
                  ) : (
                    <li className='pricing-list--separator'>{element.title}</li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>

          <div className='pricing-icon'>
            <IconPlus />
          </div>

          <h3 className='pricing-title--support'>
            Choose the support that you need
          </h3>
        </section>

        <StickyHeader data={cardData} />

        <TablePricing />

        <span className='w-100 flex-start pl-2'>
          {t("plan.tab-consumption-pricing.laius")}
        </span>
      </div>
    </PricingProvider>
  );
};
