import { Trans } from 'react-i18next';
import '../../static/scss/error.scss';

export const ErrorHeaderServiceDisabled = () => {
    return (
        <div className="error">
            <span className='color-error'>
                <Trans
                    i18nKey="error.servicesDeactivated"
                    components={[
                        <a
                            href="mailto:support.services@wassa.io"
                            className="underline"
                        />,
                    ]}
                />
            </span>
        </div>

    );
};

