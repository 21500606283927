import React, { useState } from 'react';
import CustomButton from '../button/button';
import '../../static/scss/header.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBackSvg } from '../../static/icons/icon_fleche-color.svg';
import Modal, { IModal } from '../modal/Modal';
import { useHeader } from '../../hook/useHeader';
import { ErrorHeaderCardExpired } from '../error/ErrorHeaderCardExpired';
import { convertSize } from '../../services/tools';
import useWindowDimensions from '../../hook/useWindowDimensions';
import MenuMobile from '../mobile/menu/MenuMobile';
import { serviceErrorsState } from '../../recoil/services.atom';

// Logo mobile
import { ReactComponent as LogoMobile } from '../../static/images/logo-mobile.svg';
import { useRecoilValue } from 'recoil';
import { ErrorHeaderServiceDisabled } from '../error/ErrorHeaderServiceDisabled';

const Header: React.FC<any> = () => {
    const { push } = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const [iconState, setIconState] = useState({ color: '#333' });
    const [modalState, setModalState] = useState({} as IModal);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const { getClientName, handleLogout, getTrialState, isTrial } = useHeader();
    const { width } = useWindowDimensions();
    const serviceUnavailable = useRecoilValue(serviceErrorsState);

    const openModalLogout = () => {
        setModalState({
            show: true,
            showCancel: true,
            message: t('logout.message'),
            onConfirm: () => handleLogout(),
            onCancel: () => setModalState({ ...modalState, show: false }),
            confirmBtnText: t('logout.quit'),
            cancelBtnText: t('logout.cancel'),
        });
    };

    const handleIconState = (mouseAction: string) => {
        if (mouseAction === 'enter') {
            setIconState({ color: '#009BDE' });
        } else {
            setIconState({ color: '#333' });
        }
    };

    const displayHeaderTitle = () => {
        const pagesWithBackButton = [
            {
                paths: ['/account/edit/account', '/account/edit/billing', '/account/plan'],
                goTo: '/account',
                isSubsection: false,
            },
            {
                paths: [
                    '/services/blurIt',
                    '/services/facelytics',
                    '/services/soiling',
                    '/services/orientation',
                    '/services/congestion',
                    '/services/watermark',
                    '/services/vehiclesAndPedestriansDetection',
                    '/services/identity',
                ],
                goTo: '/services',
                isSubsection: true,
            },
            {
                paths: ['/payment/standard/step-2', '/payment/premium/step-2'],
                goTo: '/account/plan',
                isSubsection: false,
            },
            {
                paths: ['/payment/standard/step-3', '/payment/premium/step-3'],
                goTo: '/account',
                isSubsection: false,
            },
            {
                paths: ['/task-manager/anonymization', '/task-manager/watermark', '/task-manager/faces-attributes'],
                goTo: '/task-manager',
                isSubsection: true,
            },
        ];

        const isPageWithGoBack = pagesWithBackButton.find((page) => page.paths.includes(location.pathname));
        let title = location.pathname;
        let subsection = '';

        if (isPageWithGoBack && isPageWithGoBack.isSubsection) {
            title = `/${isPageWithGoBack.paths[0].split('/')[1]}`;
            subsection = location.pathname;
        }

        if (location.pathname.split('/')[1] === 'task-manager') {
            title = '/task-manager';
        }

        return (
            <>
                {isPageWithGoBack && (
                    <div className="mr-2 d-none d-lg-block">
                        <ArrowBackSvg
                            height={15}
                            width={15}
                            onMouseEnter={() => handleIconState('enter')}
                            onMouseLeave={() => handleIconState('leave')}
                            style={{
                                cursor: 'pointer',
                            }}
                            fill={iconState.color}
                            onClick={() => {
                                push(isPageWithGoBack.goTo);
                                setIconState({ color: '#333' });
                            }}
                        />
                    </div>
                )}
                <h1 className="mb-0 mr-1 main-title">
                    {width < 992 && mobileSubsectionAsTitle.includes(location.pathname) ? t(`header.${subsection}`) : t(`header.${title}`)}
                </h1>
                {isPageWithGoBack && isPageWithGoBack.isSubsection && <span className="page-subsection d-none d-lg-block">{t(`header.${subsection}`)}</span>}

                {isPageWithGoBack && width < 992 && (
                    <button
                        type="button"
                        className="button btn-menu btn-header-task active"
                        onClick={() => {
                            push(isPageWithGoBack.goTo);
                            setIconState({ color: '#333' });
                        }}
                    ></button>
                )}
            </>
        );
    };

    const handleMobileMenu = () => {
        setShowMenu(true);
    };

    const mobileSubsectionAsTitle: string[] = ['/task-manager/anonymization', '/task-manager/watermark'];
    const mobileLinksCloseList: string[] = ['/task-manager/anonymization', '/task-manager/watermark', '/account/edit/account'];

    return (
        <header
            className={`header px-lg-5 align-items-center justify-content-between${width < 992 && mobileLinksCloseList.includes(location.pathname) ? ' header-task' : ''
                }`}
        >
            <div className="d-flex align-items-center">
                <div className="mr-1 d-flex d-lg-none d-xl-none">
                    <LogoMobile />
                </div>

                {displayHeaderTitle()}
            </div>
            <div className="d-flex align-items-center">
                {isTrial && (
                    <div className="d-none d-lg-flex d-xl-flex align-items-center trial-section me-4">
                        <span className="title">TRIAL VERSION - &nbsp; </span>
                        <span className="data mr-2">{getTrialState() ? convertSize(getTrialState()) : null} remaining</span>
                        <CustomButton buttonType="button" buttonText="Change plan" onClick={() => push('/account/plan')} />
                    </div>
                )}

                {
                    serviceUnavailable.INACTIVE_SERVICES &&
                    <div className="d-none d-lg-flex d-xl-flex me-4">
                        <ErrorHeaderServiceDisabled />
                    </div>
                }

                {
                    serviceUnavailable.CARD_EXPIRED && !serviceUnavailable.INACTIVE_SERVICES &&
                    <div className="d-none d-lg-flex d-xl-flex me-4">
                        <ErrorHeaderCardExpired />
                    </div>
                }

                {width < 992 && mobileLinksCloseList.includes(location.pathname) ? null : (
                    <div className={`d-flex align-items-center`}>
                        <CustomButton
                            buttonType="button"
                            buttonText={getClientName()}
                            customClass="mr-1 mr-lg-2"
                            classNameType="account"
                            onClick={() => push('/account')}
                        />
                        <CustomButton buttonType="button" buttonText="" classNameType="disconnect" customClass="d-none d-lg-block" onClick={openModalLogout} />
                        <CustomButton
                            buttonType="button"
                            buttonText=""
                            classNameType="menu"
                            customClass="d-block d-lg-none d-xl-none"
                            onClick={handleMobileMenu}
                        />
                    </div>
                )}
            </div>
            <Modal {...modalState} />
            <MenuMobile active={showMenu} setActive={setShowMenu} />
        </header>
    );
};

export default Header;
