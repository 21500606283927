import React, { createContext, useContext, useEffect, useState } from 'react';
import { consumptionContext, ConsumptionState } from './consumptionContext';
import { servicesContext, ServicesState } from './servicesContext';
import { clientServicesContext, ClientServicesState } from './clientServicesContext';
import { useTranslation } from 'react-i18next';
import { formatApiCallsAndConsumption, formatSelectOptions, formatActiveServices } from '../pages/dashboard/helper/formatData';
import moment from 'moment';
import { clientContext } from './clientContext';

type DashboardData = {
    isActive: boolean;
    totalConsumption: string;
    totalConsumptionThisMonth: string;
    totalConsumptionThisDay: string;
    totalCall: number;
    totalCallThisMonth: number;
    totalCallThisDay: number;
};

type Dashboard = {
    consumptionState: ConsumptionState;
    servicesState: ServicesState;
    clientServiceState: ClientServicesState;
    isLoading: boolean;
    servicesListSelect: { label: string; value: string }[] | [];
    apiCallsAndConsumption: DashboardData;
    dashboardTableData: any;
    refetchData: (value: boolean) => void;
    handleSelect: (e: any) => void;
    selectOptions: any;
    selected: any;
    refetch: boolean;
};

const dashboardContext = createContext({} as Dashboard);

const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [dashboardState, setDashboardState] = useState({} as Dashboard);
    const [refetch, setRefetch] = useState(false);
    const consumptionState = useContext(consumptionContext);
    const servicesState = useContext(servicesContext);
    const clientServiceState = useContext(clientServicesContext);
    const clientState = useContext(clientContext);
    const [selectOptions, setSelectOptions] = useState<any[] | null>(null);
    const [selected, setSelect] = useState<any>({ label: 'Faces attributes', value: 'SRV_FACE' });
    const { t } = useTranslation();
    const refetchData = (value: boolean) => {
        setRefetch(value);
    };

    const handleSelect = (e: any) => {
        setSelect(e);
        refetchData(true);
    };

    useEffect(() => {
        if (refetch && clientState.dataClient) {
            (async () => {
                setDashboardState({
                    ...dashboardState,
                    isLoading: true,
                });

                //api calls
                const thisDayConsumption = await consumptionState.getData({
                    params: {
                        service: selected?.value,
                        period: 'day',
                        dateStart: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        isTrial: clientState.dataClient?.isTrial,
                    },
                });
                const thisMonthConsumption = await consumptionState.getData({
                    params: {
                        service: selected?.value,
                        period: 'month',
                        dateStart: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                        isTrial: clientState.dataClient?.isTrial,
                    },
                });

                let i = 1;
                let services = await servicesState.getData();
                let servicesElements = services.elements;
                while (i++ <= services.totalPage) {
                    servicesElements = [...servicesElements, ...(await servicesState.getData({ params: { page: i } })).elements];
                }
                const clientServices = await clientServiceState.getData();
                //format data for select
                if (!selectOptions) {
                    const servicesListSelect = formatSelectOptions(servicesElements, t);
                    setSelect(servicesListSelect.selected);
                    setSelectOptions(servicesListSelect.options);
                }
                //set data into global store
                setDashboardState({
                    ...dashboardState,
                    isLoading: false,
                    selected,
                    dashboardTableData: formatActiveServices(clientServices, servicesElements),
                    apiCallsAndConsumption: formatApiCallsAndConsumption(thisDayConsumption, thisMonthConsumption, clientServices, selected),
                });
                setRefetch(false);
            })();
        }
    }, [refetch, clientState.dataClient]);

    return (
        <dashboardContext.Provider
            value={{
                ...dashboardState,
                consumptionState,
                servicesState,
                clientServiceState,
                selectOptions,
                selected,
                handleSelect,
                refetchData,
            }}
        >
            {children}
        </dashboardContext.Provider>
    );
};

export { DashboardProvider, dashboardContext };
