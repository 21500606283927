import React, { useState } from 'react';
import { randomKeyGen } from '../../../services/tools';
import useWindowDimensions from '../../../hook/useWindowDimensions';

// Icon
import { ReactComponent as IconChevron } from '../../../static/icons/icon-chevron-mobile.svg';

export interface IPlanCard {
    name: string
    title: string
    price: number
    laius?: string
    options?: string[]
    button?: React.ReactElement<any>
    customClass?: string,
    plan?: string
    laius2?: string
};

export const PlanCard: React.FC<IPlanCard> = ({ title, price, laius, options, button, customClass, plan = 'account', laius2 = null }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    const handleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={`card-container d-flex flex-column align-items-center justify-content-center ${customClass}`}>
            <h3 className='title mb-2'>{title}</h3>
            <div className='price d-flex flex-end mb-2'>
                <h2>{price}  </h2>
                <span className="color-primary euro">€*</span>
                <span className='color-black'>&nbsp;/ Month</span>
            </div>
            {
                laius &&
                <div className="laius">
                    <p>{laius}</p>
                </div>
            }
            {
                width < 992 &&
                <button
                    type="button"
                    className="button btn-collapse btn-collapse-card"
                    aria-controls="collapse"
                    aria-expanded={expanded}
                    onClick={handleExpanded}
                >
                    <IconChevron fill="#F50A6B" />
                </button>
            }
            <div id="collapse" className="card-container-collapse" data-expanded={expanded}>
                {
                    plan === 'payment' && <hr />
                }
                {
                    options &&
                    <>
                        <ul className="options-list mb-4">
                            {
                                options.map(option => <li key={randomKeyGen()} className='price-card-item'>{option}</li>)
                            }
                        </ul>
                        {
                            plan === 'payment' && <hr />
                        }
                    </>
                }
            </div>
            {laius2 && <small>{laius2}</small>}
            {button}
        </div>
    );
}
