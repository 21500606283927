import React, { useState } from 'react';
import { ReactComponent as FilterSVG } from '../../static/icons/icon_filters-grey.svg';
import RoundedLoader from '../loader/RoundedLoader';
import CustomSelect from '../select/CustomSelect';
import useWindowDimensions from '../../hook/useWindowDimensions';
import '../../static/scss/filter.scss';

// Icon
import { ReactComponent as IconFilter } from '../../static/icons/icon-filter.svg';

type FilterInvoicesProps = {
  title: string,
  selectProps: {
    onChange: (e: any) => void,
    options: any[]
    value: any,
    placeholder?: string
  },
  loading: boolean
}

const FilterInvoices: React.FC<FilterInvoicesProps> = ({
  title,
  selectProps,
  loading
}) => {
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);

  const { width } = useWindowDimensions();
  const handleFilter = () => {
    setVisibleFilter(!visibleFilter);
  };

  return (
    <div className='filter-no-collapse d-flex justify-content-between align-items-center'>
      <div className='d-none d-lg-flex d-xl-flex align-items-center'>
        <FilterSVG height='20' width='20' className="d-none d-lg-block d-xl-block" />
        <h2 className='ms-lg-4 mb-0'>{title}</h2>
      </div>

      <div className={`${width < 992 ? 'col-12 ' : ''}d-flex align-items-center flex-wrap justify-content-between justify-content-lg-end`}>
        <h2 className="filter-service-title d-flex d-lg-none d-xl-none">{title}</h2>

        <button
          type="button"
          className={`button btn-filter-mobile${visibleFilter ? ' active' : ''} ms-auto`}
          onClick={handleFilter}
        >
          {
            loading ? (
              <RoundedLoader isLoading={loading} />
            ) : (
              <IconFilter fill="#6B7897" />
            )
          }
        </button>

        <div className='loader-container mx-lg-2 d-none d-lg-block d-xl-block'>
          <RoundedLoader isLoading={loading} />
        </div>
        
        <div className="d-flex align-items-center w-100-mobile" data-open={visibleFilter}>
          <div className='select-container'>
            <CustomSelect {...selectProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterInvoices;
