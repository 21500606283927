import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { useHeader } from '../../../hook/useHeader';
import { convertSize } from '../../../services/tools';
import { ErrorHeaderCardExpired } from '../../error/ErrorHeaderCardExpired';

// Style
import './menuMobile.scss';

// Logo
import { ReactComponent as LogoFacelytics } from '../../../static/images/logo-facelytics-mobile.svg';
import Modal, { IModal } from '../../modal/Modal';
import { useRecoilValue } from 'recoil';
import { serviceErrorsState } from '../../../recoil/services.atom';
import { ErrorHeaderServiceDisabled } from '../../error/ErrorHeaderServiceDisabled';

type Menu = {
    active: boolean; // Get
    setActive: any; // Set
};

const MenuMobile: React.FC<Menu> = ({ active, setActive }) => {
    const { t } = useTranslation();
    const { handleLogout, getTrialState, isTrial } = useHeader();
    const { push } = useHistory();
    const [modalState, setModalState] = useState({} as IModal);
    const serviceUnavailable = useRecoilValue(serviceErrorsState)

    const onClickLogout = () => {
        setModalState({
            show: true,
            title: t('logout.quit'),
            message: t('logout.message'),
            cancelBtnText: t('logout.cancel'),
            confirmBtnText: t('logout.quit'),
            onCancel: () => setModalState({ ...modalState, show: false }),
            onConfirm: handleLogout,
            showCancel: true,
        });
    };

    const navBarItems = [
        {
            linkTo: '/dashboard',
            title: t('navbar.dashboard'),
            icon: 'icon-dashboard',
            translation: t('navbar.dashboard'),
        },
        {
            linkTo: '/task-manager',
            title: t('navbar.task-manager'),
            icon: 'icon-taskmanager',
            translation: t('navbar.task-manager'),
        },
        {
            linkTo: '/account',
            title: t('navbar.account'),
            icon: 'icon-account',
            translation: t('navbar.account'),
        },
        {
            linkTo: '/developer',
            title: t('navbar.developer'),
            icon: 'icon-developer',
            translation: t('navbar.developer'),
        },
    ];

    const redirectAndClose = (url: string) => {
        setActive(!active);
        push(url);
    };

    return (
        <div className={`menu-mobile ${active ? 'show' : ''}`} aria-hidden={!active} tabIndex={active ? 0 : -1}>
            <div className="menu-mobile-header">
                <div className="logo">
                    <LogoFacelytics />
                </div>

                <button type="button" className="button btn-menu active" onClick={() => setActive(!active)}></button>
            </div>

            <div className="menu-mobile-body">
                <nav role="navigation">
                    <ul>
                        {navBarItems.map((element, key) => (
                            <li key={key}>
                                <NavLink to={element.linkTo} className={element.icon} activeClassName="active" onClick={() => setActive(!active)}>
                                    {element.title}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>

                {isTrial && (
                    <div className="trial">
                        <div className="trial-text">
                            <p className="title">TRIAL VERSION</p>
                            <p className="data">{getTrialState() ? convertSize(getTrialState()) : null} remaining</p>
                        </div>

                        <div className="trial-action">
                            <button type="button" className="button btn-main" onClick={() => redirectAndClose('/account/plan')}>
                                Change plan
                            </button>
                        </div>
                    </div>
                )}

                {
                    serviceUnavailable.INACTIVE_SERVICES &&
                    <div className='trial'>
                        <ErrorHeaderServiceDisabled />
                    </div>
                }

                {
                    serviceUnavailable.CARD_EXPIRED && !serviceUnavailable.INACTIVE_SERVICES &&
                    <div className='trial trial-error'>
                        <ErrorHeaderCardExpired />
                    </div>
                }

            </div>

            <div className="menu-mobile-footer">
                <button type="button" className="button btn-deco" onClick={onClickLogout}>
                    {t('logout.quit')}
                </button>

                <p className="copyright">© 2022 Wassa - All right reserved</p>
            </div>
            <Modal {...modalState} />
        </div>
    );
};

export default MenuMobile;
