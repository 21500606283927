import { ReactComponent as RoundLoaderSvg } from '../../static/icons/round-loader.svg';

const RoundedLoader = ({ isLoading = false, size = 30, margin = "auto" }) => {

  if (!isLoading) {
    return null;
  }

  return (
    <RoundLoaderSvg height={size} width={size} style={{margin}} />
  );
};

export default RoundedLoader;
