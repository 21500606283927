import React, { useContext } from 'react';
import { clientContext } from '../../context/clientContext';
import { PaymentStep2Trial } from './components/step2Trial';
import { PaymentStep2Payable } from './components/step2Payable';

export const PaymentStep2: React.FC = () => {
    const { dataClient } = useContext(clientContext);

    if (dataClient?.isTrial) {
        return <PaymentStep2Trial />;
    } else {
        return <PaymentStep2Payable />;
    }
};
