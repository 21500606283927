import React from "react";
import { randomKeyGen } from "../../../services/tools";
import "./table-plan.scss";

export interface IContent
  extends React.DetailedHTMLProps<
      React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
      HTMLTableHeaderCellElement
    >,
    React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    > {
  content: any;
  title?: any;
}

interface IplanTableData {
  thData?: IContent;
  tdData?: IContent[];
}

export interface IPlanTab {
  planTableHeads: IContent[];
  planTableData: IplanTableData[];
  page?: "account" | "payment";
}

export const PlanTab: React.FC<IPlanTab> = ({
  planTableHeads,
  planTableData,
  page = "account",
}) => {
  return (
    <table className={`table-plan ${page === "payment" && "payment"}`}>
      <thead>
        <tr>
          {planTableHeads.map((th) => (
            <th key={randomKeyGen()} style={th.style} className={th.className}>
              {th.content}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {planTableData.map(({ thData, tdData }) => (
          <tr key={randomKeyGen()}>
            <th {...thData}>{thData?.content}</th>
            {tdData?.map((td, idx) => (
              <td {...td} key={randomKeyGen()}>
                {td.title}
                {td.content}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
