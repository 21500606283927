import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { BreadeCrumbItem } from './breadcrumbItem';

export const BreadCrumb = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const steps: Record<string, number> = {
        'step-2': 0,
        'step-3': 1
    };
    //@ts-ignore
    const data = t('payment.breadcrumb', { returnObjects: true }).map(d => d);


    const getItemStatus = (index: number) => {
        const urlArray = location.pathname.split('/');
        const step: string = urlArray[urlArray.length - 1];

        return steps[step] === index
            ? 'active'
            : steps[step] > index
                ? 'complete'
                : 'inactive'
    };

    return (
        <div className="my-breadcrumb d-flex align-items-center">
            {
                data.map(
                    (translation: any, index: number) => (
                        <BreadeCrumbItem translation={translation.title} key={'translation' + index} id={`${index + 1}`} className={getItemStatus(index)} />
                    )
                )
            }
        </div>
    );

};
