import React, { useState } from 'react';
import '../../static/scss/sidebar.scss';
import LogoSvg from '../../static/images/logo-facelytics.svg';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

type NavbarItemsProps = {
    items: NavItem[];
    isSubmenu?: boolean;
};

const NavbarItems: React.FC<NavbarItemsProps> = ({ items, isSubmenu }) => {
    const location = useLocation();

    return (
        <ul>
            {items.map((item, key) => {
                const openSubmenu = location.pathname.includes(item.linkTo) && !!item.subMenu;
                return (
                    <>
                        <li className={`${isSubmenu ? 'sub-menu' : ''}`} key={key}>
                            <NavLink
                                key={key}
                                to={item.linkTo}
                                title={item.title}
                                className={`${item.icon} ${isSubmenu ? 'sub-menu' : ''} ${openSubmenu ? 'sub-menu-parent' : ''}`}
                                activeClassName="active"
                            >
                                <span>{item.translation}</span>
                            </NavLink>
                        </li>
                        {item.subMenu && <NavbarItems items={item.subMenu} isSubmenu={true} />}
                    </>
                );
            })}
        </ul>
    );
};

type NavItem = {
    linkTo: string;
    title: string;
    icon: string;
    translation: string;
    subMenu?: NavItem[];
};

const Sidebar: React.FC = () => {
    const { t } = useTranslation();

    const navBarItems: NavItem[] = [
        {
            linkTo: '/task-manager',
            title: t('navbar.task-manager'),
            icon: 'icon-taskmanager',
            translation: t('navbar.task-manager'),
            subMenu: [
                /*{
                    linkTo: '/task-manager/anonymization',
                    title: t('navbar.anonymization'),
                    icon: '',
                    translation: t('navbar.anonymization'),
                },
                {
                    linkTo: '/task-manager/watermark',
                    title: t('navbar.watermark'),
                    icon: '',
                    translation: t('navbar.watermark'),
                },
                {
                    linkTo: '/task-manager/faces-attributes',
                    title: t('navbar.faces-attributes'),
                    icon: '',
                    translation: t('navbar.faces-attributes'),
                },*/
            ],
        },
        {
            linkTo: '/dashboard',
            title: t('navbar.dashboard'),
            icon: 'icon-dashboard',
            translation: t('navbar.dashboard'),
        },
        {
            linkTo: '/account',
            title: t('navbar.account'),
            icon: 'icon-account',
            translation: t('navbar.account'),
        },
        {
            linkTo: '/developer',
            title: t('navbar.developer'),
            icon: 'icon-developer',
            translation: t('navbar.developer'),
        },
    ];

    return (
        <aside className="sidebar-container d-none d-lg-block d-xl-block">
            <div className="wis-logo-container mb-3">
                <img className="w-100" src={LogoSvg} alt="logo WIS Manager" />
            </div>
            <nav className="nav" role="navigation" aria-label="Aside navigation">
                <NavbarItems items={navBarItems} />
            </nav>
        </aside>
    );
};

export default Sidebar;
