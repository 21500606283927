import React from 'react';
import { useTranslation } from 'react-i18next';
import logoCheck from '../../../static/icons/tasks/check-mark.png';

interface INoPreview {
    height?: string
    width?: string
    withMessage?: boolean
};

export const NoPreview: React.FC<INoPreview> = ({ height = "100", width = "100", withMessage = true }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={logoCheck} height={height} width={width} alt='no-preview-available' />
            {withMessage && <span className="color-primary mt-4">{t("task-management.tasks.no-preview-available")}</span>}
        </div>
    );
};