import React, { useContext, useEffect, useState } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import PageLayout from '../layouts/pageLayout';
import Cookies from 'js-cookie';
import { ClientProvider } from '../context/clientContext';
import { socketContext } from '../context/socketContext';
import { useQuery } from '../services/hook/useQuery';

interface IPrivateRoute extends RouteProps {
    providers?: any[];
    layout?: React.FC<{ children: React.ReactNode }>;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({ component: Component, providers, layout: Layout = PageLayout, ...rest }) => {
    const auth = Cookies.getJSON('Auth_manager');
    const [isConnected, setIsConnected] = useState(false);
    const socketStore = useContext(socketContext);
    const query = useQuery();

    useEffect(() => {
        if (auth && auth.token) {
            setIsConnected(true);
            if (!socketStore.isConnected) {
                socketStore.initSocket(auth.token);
            }
        } else {
            setIsConnected(false);
            socketStore?.disconnectSocket();
        }
    }, [rest.location]);

    if (auth && !isConnected) {
        return null;
    }

    const redirection = query.get('redirect');
    if (redirection && isConnected) {
        return <Redirect to={redirection} />;
    }

    if (!isConnected) {
        let login = '/login';
        if (redirection) {
            login = `${login}?redirect=${redirection}`;
        }
        return <Redirect to={login} />;
    }

    let CustomRoute = <Route component={Component} {...rest} />;
    if (providers) {
        CustomRoute = providers.reduce((acc, Provider: any) => {
            return <Provider>{acc}</Provider>;
        }, <Route component={Component} {...rest} />);
    }

    return (
        <ClientProvider>
            <Layout>{CustomRoute}</Layout>
        </ClientProvider>
    );
};

export default PrivateRoute;
