import React, { useContext, useState } from 'react';
import { dashboardContext } from '../../context/DashboardContext';
import { ReactComponent as CallsSvg } from '../../static/icons/dashboard/calls.svg';
import { ReactComponent as ConsSvg } from '../../static/icons/dashboard/icon_download.svg';
import { useTranslation } from 'react-i18next';
import TotalDataGraphs from '../../components/graphs/totalDataGraphs';
import DataPerMonthGraph from '../../components/graphs/dataPerMonthGraph';
import moment from 'moment';
import useWindowDimensions from '../../hook/useWindowDimensions';

type Option = {
  label: string;
  value: string;
};

interface IInnerCard {
  title: string
  value: string | number
  isLoading?: boolean
  type: 'consumption' | 'apiCalls',
  isHovered?: boolean,
  handleOnHover?: any
};

const InnerCard: React.FC<IInnerCard> = ({
  title,
  value,
  type,
  isHovered,
  handleOnHover
}) => {

  let dataValue = value;
  let suffix = '';

  if (type === 'consumption' && dataValue !== 'NAN' && dataValue !== '-' && typeof dataValue === 'string') {
    if (dataValue.includes('MB')) {
      dataValue = dataValue.replace('MB', '');
      suffix = 'MB';
    } else if (dataValue.includes('KB')) {
      dataValue = dataValue.replace('KB', '');
      suffix = 'KB';
    } else if (dataValue.includes('GB')) {
      dataValue = dataValue.replace('GB', '');
      suffix = 'GB';
    } else if (dataValue.includes('Byte')) {
      dataValue = dataValue.replace('Byte', '');
      suffix = 'Byte';
    }
    else {
      dataValue = '';
    }
  }
  return (
    <div className={`d-flex inner-card justify-content-center align-items-center h-100 ${isHovered ? "hovered" : ""}`}
      onMouseEnter={handleOnHover}
    >
      <div className='mx-4 d-none d-lg-block'>
        {
          {
            'apiCalls': <CallsSvg height={50} width={50} fill="#66C300" />,
            'consumption': <ConsSvg height={50} width={50} fill="#66C300" />,
          }[type]
        }
      </div>
      <div className='mx-0 mx-lg-4'>
        <h3 className={`mb-1 title ${title === 'Total' ? 'total' : ''}`}>{title}</h3>
        <div className='d-flex align-items-center align-items-lg-end justify-content-lg-start justify-content-center flex-column flex-lg-row'>
          <h3 className={`data mb-0 ${type} mr-lg-1${dataValue && dataValue.toString().length > 5 ? ' small-number' : ''}`}>{dataValue}</h3>
          <span className={`${type} ${type !== 'apiCalls' ? 'd-block' : 'd-none d-lg-block'}`}>
            {
              {
                'apiCalls': 'CALLS',
                'consumption': `${suffix}`
              }[type]
            }
          </span>
        </div>
      </div>
    </div>
  );
};

const Dashboard: React.FC = () => {

  const data = useContext(dashboardContext);
  const { t } = useTranslation();
  const [blockSelected, setBlockSelected] = useState("total");
  const [showMonthlyGraph, setShowMonthlyGraph] = useState<boolean>(true);
  const { width } = useWindowDimensions();

  // TODO: TOUT REGROUPER DANS UN CONTEXT
  const yearlyAccountsOptions = (): Option[] => {
    const options: Option[] = [];
    for (let i = moment().year(); i > 2019; i--) {
        options.push({ label: i.toString(), value: i.toString() });
    }
    return options;
  };

  const [selected, setSelected] = useState(yearlyAccountsOptions()[0]);

  const handleSelect = (selected: any) => {
    setSelected(selected);
  };

  return (
    <>
      <div className='middle-container dash-mobile-container bordered mb-3'>
        <h2 className='mb-2 mb-lg-4'>{t('dashboard.total-api-calls')}</h2>
        <div className='row flex row'>
          <div className='col-4'>
            <InnerCard
              isHovered={blockSelected === 'total'}
              handleOnHover={() => setBlockSelected("total")}
              title={t('dashboard.total')}
              value={data.isLoading ? '-' : data?.apiCallsAndConsumption?.totalCall}
              type='apiCalls'
            />
          </div>
          <div className='col-4'>
            <InnerCard
              isHovered={blockSelected === 'monthly'}
              handleOnHover={() => setBlockSelected("monthly")}
              title={t('dashboard.current-month')}
              value={data.isLoading ? '-' : data?.apiCallsAndConsumption?.totalCallThisMonth}
              type='apiCalls'
            />
          </div>
          <div className='col-4'>
            <InnerCard
              isHovered={blockSelected === 'daily'}
              handleOnHover={() => setBlockSelected("daily")}
              title={t('dashboard.this-day')}
              value={data.isLoading ? '-' : data?.apiCallsAndConsumption?.totalCallThisDay}
              type='apiCalls'
            />
          </div>
        </div>
      </div>

      <div className='middle-container dash-mobile-container bordered mb-3'>
        <h2 className='mb-2 mb-lg-4'>{t('dashboard.total-consumption')}</h2>
        <div className='row flex row'>
          <div className='col-4'>
            <InnerCard
              isHovered={blockSelected === 'total'}
              handleOnHover={() => setBlockSelected("total")}
              title={t('dashboard.total')}
              value={data.isLoading ? '-' : data?.apiCallsAndConsumption?.totalConsumption}
              type='consumption'
            />
          </div>
          <div className='col-4'>
            <InnerCard
              isHovered={blockSelected === 'monthly'}
              handleOnHover={() => setBlockSelected("monthly")}
              title={t('dashboard.current-month')}
              value={data.isLoading ? '-' : data?.apiCallsAndConsumption?.totalConsumptionThisMonth}
              type='consumption'
            />
          </div>
          <div className='col-4'>
            <InnerCard
              isHovered={blockSelected === 'daily'}
              handleOnHover={() => setBlockSelected("daily")}
              title={t('dashboard.this-day')}
              value={data.isLoading ? '-' : data?.apiCallsAndConsumption?.totalConsumptionThisDay}
              type='consumption'
            />
          </div>
        </div>
      </div>

      <div className='row flex-row'>
        {
          width < 992 &&
          <div className="col-12 w-100">
            <div className="d-flex mx-4 justify-content-between">
              <button
                type="button"
                className={`button tab-button ${showMonthlyGraph ? 'active' : ''}`}
                onClick={() => setShowMonthlyGraph(true)}
              >
                {t('dashboard.total-per-year-graph.title-mobile')}
              </button>

              <button
                type="button"
                className={`button tab-button ${!showMonthlyGraph ? 'active' : ''}`}
                onClick={() => setShowMonthlyGraph(false)}
              >
                {t('dashboard.total-per-month-graph.title-mobile')}
              </button>
            </div>
          </div>
        }

        <div className='col-12 col-xl-6'>
          <div className={`inner-card calendar ${blockSelected === 'total' ? "hovered" : ""}`} hidden={!showMonthlyGraph}>
            <TotalDataGraphs service={data?.selected?.value} selected={selected} options={yearlyAccountsOptions()} handleSelect={handleSelect} />
          </div>
        </div>

        <div className='col-12 col-xl-6'>
          <div className={`inner-card calendar ${blockSelected === 'monthly' ? "hovered" : ""}`} hidden={showMonthlyGraph}>
            <DataPerMonthGraph service={data?.selected?.value} currentYear={selected.value} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
