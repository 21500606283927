import moment from 'moment';

const xAxisCategories = moment.monthsShort();

export const fakeData = {

    series: [
        {
            name: 'API Calls',
            type: 'column',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: 'Total Consumption (Mb) ',
            type: 'column',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: false
            },
            offsetX: -15,
        },
        colors: ['#f50a6b', '#33e394'],
        xaxis: {
            categories: xAxisCategories,
        },
        dataLabels: {
            enabled: false,
        },
        yaxis: [
            {
                seriesName: 'API Calls',
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#f50a6b'
                },
                labels: {
                    style: {
                        colors: '#f50a6b',
                    }
                },
                title: {
                    text: "API Calls",
                    style: {
                        color: '#f50a6b',
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: 'Total Consumption (Mb)',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#33e394'
                },
                labels: {
                    style: {
                        colors: '#33e394',
                    }
                },
                title: {
                    text: "Consumption (Mb)",
                    style: {
                        color: '#33e394',
                    }
                },
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft',
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            offsetY: 10
        }
    },
};

export const fakeData2 = {

    series: [
        {
            name: 'API calls',
            type: 'column',
            data: [230, 26, 480, 89, 157, 12, 8, 0, 56, 404, 82, 96]
        },
        {
            name: 'Consumption (Mb)',
            type: 'column',
            data: [1302, 4894, 6652, 270, 4578, 2231, 8896, 700, 7002,669, 1554, 475]
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: false
            },
            offsetX: -15,
            offsetY: 10,
            height: '100%'
        },
        dataLabels: {
            enabled: false,
        },
        colors: ['#f50a6b', '#33e394'],
        xaxis: {
            categories: xAxisCategories,
            labels: {
                style: {
                    bottom: 0
                }
            }
        },
        yaxis: [
            {
                seriesName: 'API calls',
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#f50a6b'
                },
                labels: {
                    style: {
                        colors: '#f50a6b',
                    }
                },
                title: {
                    text: "API Calls",
                    style: {
                        color: '#f50a6b',
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: 'Consumption (Mb)',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#33e394'
                },
                labels: {
                    style: {
                        colors: '#33e394',
                    }
                },
                title: {
                    text: "Consumption (Mb)",
                    style: {
                        color: '#33e394',
                    }
                },
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft',
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            offsetY: -15
        }
    },
};