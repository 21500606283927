import React, { useState } from 'react';
import { ReactComponent as ListSVG } from '../../static/icons/icon_list.svg';
import '../../static/scss/filter.scss';
import RoundedLoader from '../loader/RoundedLoader';
import CustomSelect from '../select/CustomSelect';

// Icon
import { ReactComponent as IconFilter } from '../../static/icons/icon-filter.svg';

type FilterServicesManagementProps = {
  title: string,
  selectProps: {
    onChange: (e: any) => void,
    options: any[]
    value: any,
    placeholder?: string
  },
  loading: boolean
};

const FilterServicesManagement: React.FC<FilterServicesManagementProps> = ({
  title,
  selectProps,
  loading
}) => {

  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);

  const handleFilter = () => {
    setVisibleFilter(!visibleFilter);
  };

  return (
    <div className='filter-no-collapse d-flex justify-content-between align-items-center'>
      <div className='d-none d-lg-flex d-xl-flex align-items-center col-12 col-md-7'>
        <ListSVG height='20' width='20' />
        <h2 className='ml-2 mb-0'>{title}</h2>
      </div>
      <div className='col-12 col-md-5 d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between justify-content-lg-end'>
        <h2 className='filter-service-title mb-0'>{title}</h2>
        <button
          type="button"
          className={`button btn-filter-mobile${visibleFilter ? ' active' : ''} ms-auto`}
          onClick={handleFilter}
        >
          {
            loading ? (
              <RoundedLoader isLoading={loading} />
            ) : (
              <IconFilter fill="#6B7897" />
            )
          }
        </button>

        <div className='loader-container mx-lg-2 d-none d-lg-block d-xl-block'>
          <RoundedLoader isLoading={loading} />
        </div>

        <div className="d-flex align-items-center w-100-mobile" data-open={visibleFilter}>
          <div className='select-container'>
            <CustomSelect {...selectProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterServicesManagement;
