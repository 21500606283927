import React, { CSSProperties } from 'react';
import '../../static/scss/checkbox.scss';

export interface ICheckbox {
    isChecked?: boolean,
    onClick?: (e: any) => void,
    value: string,
    labelText?: string
    isRequired?: boolean,
    customClass?: string,
    checkboxLabelStyle?: CSSProperties,
    checkMarkStyle?: CSSProperties,
    labelTextStyle?: CSSProperties
}

export const Checkbox = ({
    isChecked,
    onClick,
    value,
    labelText,
    isRequired,
    customClass,
    checkboxLabelStyle,
    checkMarkStyle,
    labelTextStyle
}: ICheckbox) => {
    return (
        <label className={"checkbox-container " + customClass} style={checkboxLabelStyle}>
            <input value={value} checked={isChecked} className="my-checkbox" type="checkbox" onChange={onClick} onClick={onClick} />
            <span className="checkmark" style={checkMarkStyle}></span>
            <span className="label-text" style={labelTextStyle}>{labelText}{isRequired && <sup>*</sup>}</span>
        </label>

    );
};
