import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatTable } from '../pages/taskManager/helper/formatTable';
import { historyCallContext } from './historyCallContext';
import { socketContext } from './socketContext';

type TaskManagement = {
    isLoading: boolean;
    isError: boolean;
    historyCallsTable: any;
    refetch: boolean;
    filterState: any;
    handleRefetch: (value: boolean) => void;
    handleSortTable: (e: any) => void;
    handlePage: (action: '+' | '-') => void;
    handleElementsPerPage: (e: any) => void;
    handleFilter: (keyName: string, select: any) => void;
    handleClear: () => void;
    sortState: { sortBy: string; sortOrder: 'ASC' | 'DESC' };
    paginationMeta: {
        page: number;
        pageCount: number;
    };
};

const initialState: TaskManagement = {
    isLoading: true,
    isError: false,
    historyCallsTable: [],
    refetch: true,
    filterState: {},
    sortState: { sortBy: 'date', sortOrder: 'DESC' },
    handleRefetch: () => 1,
    handleSortTable: () => 1,
    handlePage: () => 1,
    handleElementsPerPage: () => 1,
    handleFilter: () => 1,
    handleClear: () => 1,
    paginationMeta: {
        page: 1,
        pageCount: 10,
    },
};

const taskManagementContext = createContext(initialState);

const TaskManagementProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const historyCallStore = useContext(historyCallContext);
    const [refetch, setRefetch] = useState(true);
    const socketStore = useContext(socketContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (refetch) {
            historyCallStore.getAllHistoryCall();
            handleRefetch(false);
        }
        if (historyCallStore.data) {
            setState({
                ...state,
                historyCallsTable: formatTable(historyCallStore?.data?.elements, t),
            });
            handleRefetch(false);
        }
    }, [refetch, historyCallStore]);

    useEffect(() => {
        handleRefetch(true);
    }, [socketStore.task]);

    const handleRefetch = (value: boolean) => {
        setRefetch(value);
    };

    const handleSortTable = (e: any) => {
        historyCallStore.handleSort(e);
        handleRefetch(true);
    };

    const handlePage = (action: '+' | '-') => {
        historyCallStore.handlePagination(action);
        handleRefetch(true);
    };

    const handleElementsPerPage = (e: any) => {
        historyCallStore.handleElementsPerPage(e);
        handleRefetch(true);
    };

    return (
        <taskManagementContext.Provider
            value={{
                ...state,
                isLoading: historyCallStore.isLoading,
                isError: historyCallStore.isError,
                handleSortTable,
                handlePage,
                handleElementsPerPage,
                handleClear: historyCallStore.handleClear,
                handleFilter: historyCallStore.handleFilter,
                handleRefetch,
                sortState: historyCallStore.sortState,
                filterState: historyCallStore.filterState,
                paginationMeta: {
                    page: historyCallStore.data?.page ? parseInt(historyCallStore.data?.page) : 1,
                    pageCount: historyCallStore.data?.totalPage || 10,
                },
            }}
        >
            {children}
        </taskManagementContext.Provider>
    );
};

export { taskManagementContext, TaskManagementProvider };
