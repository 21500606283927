import React from 'react';
import Select, { StylesConfig } from 'react-select';

// Style
import './planSwitch.scss';

type TPlanSwitch = {
    handleSelected: (selected: number) => void;
};

// React-select style
type StateOption = {
    label: string;
    value: number;
};

const PlanSwitchMobile: React.FC<TPlanSwitch> = ({ handleSelected }) => {

    // N'existe QUE sur mobile
    const panelStyles: StylesConfig<StateOption, false> = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#FFFFFF',
            color: '#333333',
            boxShadow: 'none',
            border: '1px solid #F0F3F4',
            minWidth: '150px',
            fontSize: '0.8125rem', // 13px
            height: '42px',
            borderRadius: '4px',
            transition: 'border .25s ease',
    
            '&:focus': {
                border: '0',
                boxShadow: 'none',
            },
    
            '&:hover': {
                border: '1px solid #6B7897'
            }
        }),

        menu: (styles) => ({
            ...styles,
            borderRadius: '4px',
            fontSize: '0.8125rem', // 13px
            color: '#333333'
        }),

        option: (styles, state: any) => ({
            ...styles,
            backgroundColor: state.isSelected
            ? 'rgba(107, 120, 151, 0.8)'
            : state.isFocused
              ? 'rgba(107, 120, 151, 0.2)'
              : 'none',
        }),

        valueContainer: (styles) => ({
            ...styles,
            height: '42px',
            padding: '0px 8px'
        }),

        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: '#F0F2F4'
        })
    }

    /**
     * 0: Basic -
     * 1: Standard -
     * 2: Premium
     */
    const planList: { label: string, value: number }[] = [
        {
            label: 'Basic',
            value: 0
        },
        {
            label: 'Standard',
            value: 1
        },
        {
            label: 'Premium',
            value: 2
        }
    ];

    return (
        <div className="mobile-sticky-pricing">
            <p className="mb-0">Switch plans</p>

            <Select
                defaultValue={{ label: 'Basic', value: 0 }}
                options={planList}
                onChange={
                    (event: any) => {
                        handleSelected(event.value);
                    }
                }
                isSearchable={false}
                isClearable={false}
                styles={panelStyles}
            />
        </div>
    );
};

export default PlanSwitchMobile;
