import React, { createContext, useState } from 'react';
import { useAxios } from '../services/hook/requestsService';

export type TotalCount = {
    type: 'VIDEO' | 'PICTURE';
    size: string;
    totalCount: string;
};

type GetHistoryCallOptions = {
    params: {
        service?: string;
        period?: 'day' | 'month';
        dateStart?: string;
        isTrial?: boolean;
    };
};

export type CallsPerPeriod = {
    type: 'VIDEO' | 'PICTURE';
    searchKey: string;
    date: string;
    totalCount: string;
    size: string;
};

export type ConsumptionData = {
    totalCounts: TotalCount[];
    totalCountsPerPeriod: CallsPerPeriod[];
};

export type ConsumptionState = {
    data: ConsumptionData | null;
    identityData: any;
    error: any;
    loading: boolean;
    getData: (options?: GetHistoryCallOptions) => Promise<ConsumptionData>;
    getConsumptionDataWithoutState: (options?: GetHistoryCallOptions) => Promise<{ data: any; error: any; loading: boolean }>;
};

export const initialState: ConsumptionState = {
    error: false,
    loading: true,
    data: null,
    identityData: null,
    getData: async () => ({} as ConsumptionData),
    getConsumptionDataWithoutState: async () => ({} as { data: any; error: any; loading: boolean }),
};

const consumptionContext = createContext<ConsumptionState>(initialState);

const ConsumptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const axiosActions = useAxios({ isPrivate: true });

    const getConsumptionDataWithoutState = async (options?: GetHistoryCallOptions) => {
        return { ...(await axiosActions.getData(null, 'history-call/consumption', options)) };
    };

    const getData = async (options?: GetHistoryCallOptions) => {
        try {
            const { data } = await axiosActions.getData(null, 'history-call/consumption', options);
            const { data: identityData } = await axiosActions.getData(null, 'history-call/consumption/identity', options);
            setState({
                ...state,
                data,
                identityData,
                loading: false,
                error: false,
            });
            return data;
        } catch (e) {
            setState({
                ...state,
                data: null,
                identityData: null,
                loading: false,
                error: true,
            });
            throw e;
        }
    };

    return (
        <consumptionContext.Provider
            value={{
                ...state,
                loading: axiosActions.loading,
                getData,
                getConsumptionDataWithoutState,
            }}
        >
            {children}
        </consumptionContext.Provider>
    );
};

export { consumptionContext, ConsumptionProvider };
