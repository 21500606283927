import moment from "moment";
import { ClientService } from "../context/clientServicesContext";

export const isStrEmpty = (str: string): boolean => (str === undefined || str?.length === 0 || str === null);

export const isObjectEmpty = (obj: any) => (Object.keys(obj).length === 0 && obj.constructor === Object);

export const convertToMo = (data: any) => parseInt(Number(data / 1_048_576).toFixed());

export const randomKeyGen = () => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < 15; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const replaceEmptyByNull = (myObj: any) => {
    Object.keys(myObj).forEach(k => myObj[k] = myObj[k] === '' ? null : myObj[k])
};

export const convertSize = (a: number, b?: number) => {
    if (0 === a) {
        return "0 Byte";
    }
    let c = 1024;
    let d = b || 2;
    let e = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let f = Math.floor(Math.log(a) / Math.log(c));

    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

export const convertToMb = (a: number) => {
    return Math.floor(a / (1024*1024));
};

export const convertSizeNumber = (a: number, b?: number) => {
    if (0 === a) {
        return "0 Byte";
    }
    let c = 1024;
    let d = b || 2;
    let f = Math.floor(Math.log(a) / Math.log(c));

    return parseFloat((a / Math.pow(c, f)).toFixed(d));
};

export const convertSizeType = (a: number) => {
    if (0 === a) {
        return "0 Byte";
    }
    let c = 1024;
    let e = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let f = Math.floor(Math.log(a) / Math.log(c));

    return e[f];
};

export const isCardExpired = (creditCardExpirationDate: number | undefined) => {
    return creditCardExpirationDate && moment(creditCardExpirationDate).isBefore(new Date());
};

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const areApiActive = (services: ClientService[]) => {
    return (services?.some((e: ClientService) => !e?.service?.searchKey?.includes('SDK') && !e?.service?.searchKey?.includes('PREMISE') && !e.serviceEnd));
};
