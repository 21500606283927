import { atom, selector } from "recoil";

export const TASKS_UNAVAILABLE_TYPES = {
    CARD_EXPIRED: "CARD_EXPIRED",
    SERVICES_ERROR: "SERVICES_ERROR",
    INACTIVE_SERVICES: "INACTIVE_SERVICES",
} as const;
export type TASKS_UNAVAILABLE = typeof TASKS_UNAVAILABLE_TYPES[keyof typeof TASKS_UNAVAILABLE_TYPES];

type TaskAvailability = {
    [K in TASKS_UNAVAILABLE]: boolean;
};

export const serviceErrorsState = atom<TaskAvailability>({
    key: 'serviceErrors',
    default: {
        CARD_EXPIRED: false,
        SERVICES_ERROR: false,
        INACTIVE_SERVICES: false,
    },
});

export const serviceUsableSelector = selector<boolean>({
    key: 'serviceUsableSelector',
    get: ({ get }) => {
        const serviceUsableStae = get(serviceErrorsState);
        return !Object.values(serviceUsableStae).some((value) => value === true);
    }
});