import { Client } from "../../../context/clientContext";

export const getClientInitialValues = (client: Client) => {
    return {
        type: client.type ?? "",
        address: client.address ?? "",
        lastName: client.lastName ?? "",
        firstName: client.firstName ?? "",
        postalCode: client.postalCode ?? "",
        city: client.city ?? "",
        mail: client.mail ?? "",
        country: client.country ?? "",
        optIn: client.optIn || false,
        conditions: true,
        companyName: client.companyName ?? ""
    };
};