import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import Input from '../../../components/input/Input';
import { IPlanCard, PlanCard } from '../../../components/plans/planCard/planCard';
import CustomButton from '../../../components/button/button';
import { clientContext } from '../../../context/clientContext';
import { getClientInitialValues } from '../helper/helper';
import { validatePayment } from '../../../services/validations/paymentValidation';
import CustomSelect from '../../../components/select/CustomSelect';
import { lazy } from 'yup';
import { getCardData } from '../../../components/plans/data/data';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Checkbox } from '../../../components/checkbox/checkbox';
import { isObjectEmpty } from '../../../services/tools';
import Modal, { IModal } from '../../../components/modal/Modal';
import useWindowDimensions from '../../../hook/useWindowDimensions';

// Icon
import { ReactComponent as IconBackSvg } from '../../../static/icons/icon-back-mobile.svg';

export const PaymentStep2Trial: React.FC = (props) => {
    const { dataClient, updateClientSupport } = useContext(clientContext);
    const params: any = useParams();
    const { t } = useTranslation();
    const { push } = useHistory();
    const [modalState, setModalState] = useState({} as IModal);
    const [loading, setIsLoading] = useState(false);
    const { width } = useWindowDimensions();

    const handleChangeSelect = (values: Record<string, any>, setValues: any) => (value: any) => {
        setValues({
            ...values,
            type: value.value,
        });
    };

    const cardData = getCardData(t, params.plan) as IPlanCard;

    const getValueSelect = (valueType?: string) => {
        if (valueType === 'COMPANY') {
            return { label: 'Company', value: 'COMPANY' };
        } else {
            return { label: 'Individual', value: 'INDIVIDUAL' };
        }
    };

    const handleChangeCheckbox = (values: Record<string, any>, setValues: any) => (e: any) => {
        setValues({
            ...values,
            [e.target.value]: !values[e.target.value],
        });
    };

    const submitUpdatePlan = async (values: any) => {
        try {
            setIsLoading(true);
            const response: any = await updateClientSupport({ ...values, support: params.plan.toUpperCase(), origin: 'facelytics_app' });
            if (response.url) {
                window.location = response.url;
            }
        } catch (e) {
            setIsLoading(false);
            setModalState({
                ...modalState,
                show: true,
                showCancel: true,
                title: t('error.error-title'),
                message: t('error.internalError'),
                onConfirm: () => setModalState({ show: false }),
            });
        }
    };

    const handleFormSubmit = (values: any) => {
        setModalState({
            show: true,
            showCancel: true,
            message: t('payment.step-2.modal-message', { planType: cardData.title }),
            onCancel: () => setModalState({ ...modalState, show: false }),
            confirmBtnText: t('payment.step-2.modal-button-confirm'),
            onConfirm: () => submitUpdatePlan(values),
        });
    };

    if (!dataClient || loading) {
        return null;
    } else {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center plans-component-container step-2 pt-5 p-lg-5">
                <section className="d-flex flex-column align-items-center justify-content-center text-center mb-3 mt-5 mt-lg-0 mb-lg-5">
                    <h2>{t('payment.step-2.subscription')}</h2>
                </section>
                <section className="d-flex flex-wrap order-container">
                    <div className="d-flex d-lg-none d-xl-none order-1 step-back" aria-hidden={width > 992} onClick={() => push('/account/plan')}>
                        <IconBackSvg fill="#6b7897" />
                        Change plan
                    </div>

                    <div className="form-container me-lg-2">
                        <h2 className="mb-2">{t('payment.step-2.your-account')}</h2>
                        <Formik
                            validateOnMount={true}
                            onSubmit={handleFormSubmit}
                            initialValues={getClientInitialValues(dataClient)}
                            validationSchema={() => lazy((_) => validatePayment(dataClient))}
                        >
                            {({ handleChange, values, errors, setValues, handleSubmit }) => {
                                return (
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}
                                        className="form-trial"
                                    >
                                        <div className="d-flex flex-wrap">
                                            <div className="d-flex flex-wrap justify-content-between col-12 pe-lg-4 mb-3">
                                                <CustomSelect
                                                    isRequired={true}
                                                    isDisabled={!dataClient.isTrial}
                                                    onChange={handleChangeSelect(values, setValues)}
                                                    options={[
                                                        { label: 'Company', value: 'COMPANY' },
                                                        { label: 'Individual', value: 'INDIVIDUAL' },
                                                    ]}
                                                    value={getValueSelect(values.type)}
                                                    placeholder={t('payment.step-2.you-are-placeholder')}
                                                    customClass="mb-3"
                                                    withLabel={true}
                                                    labelTranslation={t('payment.step-2.you-are')}
                                                    order={0}
                                                />
                                                {getValueSelect(values.type).value === 'COMPANY' && <div className="form-group form-order-5 mb-3"></div>}
                                                {getValueSelect(values.type).value === 'COMPANY' && (
                                                    <Input
                                                        withLabel={true}
                                                        inputType="text"
                                                        id="companyName"
                                                        onChange={handleChange}
                                                        labelTranslation={t('payment.step-2.companyName')}
                                                        placeholder={t('payment.step-2.companyName-placeholder')}
                                                        customClassInput="pl-1"
                                                        value={values.companyName}
                                                        isRequired={true}
                                                        order={1}
                                                    />
                                                )}
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="email"
                                                    onChange={handleChange}
                                                    labelTranslation={t('payment.step-2.email')}
                                                    placeholder={t('payment.step-2.email-placeholder')}
                                                    customClassInput="pl-1"
                                                    value={values.mail}
                                                    disable={true}
                                                    isRequired={true}
                                                    order={4}
                                                />
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="lastName"
                                                    onChange={handleChange}
                                                    labelTranslation={t('payment.step-2.lastname')}
                                                    placeholder={t('payment.step-2.lastname-placeholder')}
                                                    customClassInput="pl-1"
                                                    value={values.lastName}
                                                    isRequired={true}
                                                    order={2}
                                                />
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="firstName"
                                                    onChange={handleChange}
                                                    labelTranslation={t('payment.step-2.firstname')}
                                                    placeholder={t('payment.step-2.firstname-placeholder')}
                                                    customClassInput="pl-1"
                                                    value={values.firstName}
                                                    isRequired={true}
                                                    order={3}
                                                />
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="address"
                                                    onChange={handleChange}
                                                    labelTranslation={t('payment.step-2.address')}
                                                    placeholder={t('payment.step-2.address-placeholder')}
                                                    customClassInput="pl-1"
                                                    value={values.address}
                                                    isRequired={true}
                                                    order={5}
                                                />
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="postalCode"
                                                    onChange={handleChange}
                                                    labelTranslation={t('payment.step-2.postal-code')}
                                                    placeholder={t('payment.step-2.postal-code-placeholder')}
                                                    customClassInput="pl-1"
                                                    value={values.postalCode}
                                                    isRequired={true}
                                                    order={6}
                                                />
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="city"
                                                    onChange={handleChange}
                                                    labelTranslation={t('payment.step-2.city')}
                                                    placeholder={t('payment.step-2.city-placeholder')}
                                                    customClassInput="pl-1"
                                                    value={values.city}
                                                    isRequired={true}
                                                    order={7}
                                                />
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="country"
                                                    onChange={handleChange}
                                                    labelTranslation={t('payment.step-2.country')}
                                                    placeholder={t('payment.step-2.country-placeholder')}
                                                    customClassInput="pl-1"
                                                    value={values.country}
                                                    isRequired={true}
                                                    order={8}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column mb-3">
                                            <Checkbox
                                                value="conditions"
                                                onClick={handleChangeCheckbox(values, setValues)}
                                                isChecked={values.conditions}
                                                labelText={t('payment.step-2.conditions')}
                                                isRequired={true}
                                            />
                                            <Checkbox
                                                value="optIn"
                                                onClick={handleChangeCheckbox(values, setValues)}
                                                isChecked={values.optIn}
                                                labelText={t('payment.step-2.opt-in')}
                                            />
                                        </div>
                                        <div className="d-flex w-100 justify-content-end pe-4 pb-4 pb-lg-0 align-items-end">
                                            <CustomButton
                                                onClick={() => push('/account/plan')}
                                                buttonType="button"
                                                buttonText={t('payment.step-2.button-back')}
                                                classNameType="mainWhite"
                                                customClass="mr-1"
                                            />
                                            <CustomButton
                                                customClass={!isObjectEmpty(errors) ? 'grey-dark' : ''}
                                                buttonType="submit"
                                                buttonText={t('payment.step-2.button-submit')}
                                                disabled={!isObjectEmpty(errors)}
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                    <div className="plans-card-container mb-5 mb-lg-0 mt-lg-2">
                        <PlanCard
                            title={cardData.title}
                            price={cardData.price}
                            laius={cardData.laius}
                            options={cardData.options}
                            name={cardData.name}
                            customClass={`disabled active${width < 992 ? ' card-trial-mobile' : ''}`}
                            plan="payment"
                            laius2={t('payment.step-2.laius-2')}
                        />
                    </div>
                </section>
                <Modal {...modalState} loading={loading} />
            </div>
        );
    }
};
