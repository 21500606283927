import moment from 'moment';
import React, { useContext, useState } from 'react';
import { HistoryCall } from '../../../context/historyCallContext';
import { socketContext } from '../../../context/socketContext';
import { convertSize } from '../../../services/tools';
import { DownloadButton } from '../../account/utils/downloadButton';
import '../../../static/scss/tooltip.scss';
import { TFunction } from 'i18next';

export enum BadgeStatus {
    NONE = 'none',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    FAILED = 'failed',
}

const TaskStatus: React.FC<{ taskId: string; taskStatus: string }> = ({ taskId, taskStatus }) => {
    const socketStore = useContext(socketContext);

    const getBadgeSatus = () => {
        switch (taskStatus) {
            case 'Succeeded': {
                return BadgeStatus.SUCCESS;
            }
            case 'Deleted': {
                return BadgeStatus.SUCCESS;
            }
            case 'None': {
                return BadgeStatus.NONE;
            }
            case 'Sent': {
                return BadgeStatus.PROCESSING;
            }
            case 'Started': {
                return BadgeStatus.PROCESSING;
            }
            case 'Uploading': {
                return BadgeStatus.PROCESSING;
            }
            case 'Failed': {
                return BadgeStatus.FAILED;
            }
            case 'Upload failed': {
                return BadgeStatus.FAILED;
            }
            default:
                return BadgeStatus.NONE;
        }
    };

    const getBadgeStatusText = () => {
        if (socketStore.task[taskId] && socketStore.task[taskId] === 'Started') {
            return 'Processing';
        } else {
            if (taskStatus === 'Started') {
                return 'Processing';
            }
            return taskStatus;
        }
    };

    return (
        <div className="d-flex align-items-center">
            <div className={`task-status-badge ${getBadgeSatus()} me-lg-2`}></div>
            <span>{getBadgeStatusText()}</span>
        </div>
    );
};

const TooltipName: React.FC<{ fileName: string; children: React.ReactNode }> = ({ children, fileName }) => {
    const [show, setShow] = useState(false);

    return (
        <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className="text-truncate">
            {show && <div className="tooltip-container">{fileName}</div>}
            {children}
        </div>
    );
};

export const formatTable = (data: HistoryCall[] | [], t: TFunction) => {
    const head = [
        {
            title: 'Date',
            type: 'button-sortable',
            name: 'date',
            align: 'left',
            style: {
                width: '15%',
            },
        },
        {
            title: 'Service name',
            type: 'button-sortable',
            align: 'center',
            name: 'service_name',
            style: {
                width: '12%',
            },
        },
        {
            title: 'File name',
            type: 'text',
            name: 'filename',
            align: 'left',
            data: true,
            style: {
                width: '27%',
            },
        },
        {
            title: 'File type',
            type: 'button-sortable',
            name: 'type',
            align: 'left',
            data: true,
            style: {
                width: '19%',
            },
        },
        {
            title: 'Size',
            type: 'text',
            align: 'left',
            name: 'size',
            data: true,
            style: {
                width: '10%',
            },
        },
        {
            title: 'Status',
            type: 'text',
            align: 'left',
            name: 'task_status',
        },
        {
            title: 'File',
            type: 'text',
            align: 'center',
            name: 'size',
            data: true,
            style: {
                width: '5%',
            },
        },
    ];

    const formatFileName = (element: any) => {
        if (element.status === 'Succeeded' && element.filename) {
            return `${element.filename.slice(0, element.filename.lastIndexOf('.'))}_processed_${moment(element.date)
                .utc()
                .format('YYYYMMDDhhmmss')}z${element.filename.slice(element.filename.lastIndexOf('.'), element.filename.length)}`;
        } else {
            return element.filename;
        }
    };

    const isTaskSucceeded = (status: string) => {
        return status === 'Succeeded';
    };

    const body: any[] =
        data.length > 0
            ? data.map((element: HistoryCall, index: number) => {
                const formattedFileName = formatFileName(element);
                const job_id = element.job_id;
                const linkTo = { pathname: `/task-manager/${job_id}/detail`, state: { element } };
                // mise à jour du status à "Supprimé si le fichier n'est plus disponible (après une semaine)
                if (moment(element?.date).add(7, 'days') < moment() && (element?.status as any) === 'Succeeded') {
                    (element.status as any) = 'Deleted';
                }
                return {
                    date: {
                        value: moment(element.date).format('DD-MM-YYYY_HH:mm:ss'),
                        type: 'link',
                        align: 'left',
                        name: 'date',
                        linkTo,
                    },
                    serviceName: {
                        value: t(`common.services-searchKey.${element.service_search_key}`),
                        type: 'link',
                        service: t(`common.services-searchKey.${element.service_search_key}`),
                        align: 'left',
                        linkTo,
                    },
                    filename: {
                        value: (
                            <TooltipName fileName={formattedFileName}>
                                <DownloadButton
                                    params={{
                                        searchKey: element.service_search_key,
                                        job_id: job_id,
                                        fileType: element.file_type,
                                        fileName: formattedFileName,
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${element.fileurl}`}
                                    filename={formattedFileName}
                                    buttonType="link"
                                />
                            </TooltipName>
                        ),
                        type: 'anchor',
                        align: 'left',
                        linkTo: `${process.env.REACT_APP_API_URL}/task-management/${element.fileurl}`,
                    },
                    fileType: {
                        value: element.file_type,
                        type: 'text',
                        name: 'fileType',
                        align: 'left',
                        data: true,
                    },
                    size: {
                        value: convertSize(element.size),
                        type: 'text',
                        align: 'left',
                        name: 'size',
                        data: true,
                    },
                    taskStatus: {
                        value: <TaskStatus taskId={job_id} taskStatus={element.status} />,
                        type: 'link',
                        name: 'taskStatus',
                        align: 'left',
                        linkTo,
                        taskLink: true,
                    },
                    downlaodButton: {
                        value:
                            ['SRV_FACE'].includes(element.service_search_key) && isTaskSucceeded(element.status) ? (
                                <DownloadButton
                                    params={{
                                        is_json: true,
                                        searchKey: element.service_search_key,
                                        job_id: job_id,
                                        fileType: element.file_type,
                                        fileName: formattedFileName,
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${element.fileurl}`}
                                    filename={formattedFileName}
                                    buttonType="image"
                                />
                            ) : (
                                <div></div>
                            ),
                        type: 'text',
                        align: 'center',
                        data: true,
                    },
                };
            })
            : [];

    return {
        head,
        body,
    };
};
