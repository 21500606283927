import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/button/button';
import { customCalendarContext } from '../../context/calendarContext';
import { servicesManagementContext } from '../../context/ServicesManagementContext';
import { convertSizeNumber, convertSizeType } from '../../services/tools';
import useWindowDimensions from '../../hook/useWindowDimensions';

export const ApiCallAndConsumptionTableLayout = ({ title = 'API CALL' }) => {
    const { t } = useTranslation();

    const dataType = title === 'API calls' ? 'apiCallsPerYear' : 'consumptionPerYear';

    const state = useContext(servicesManagementContext);
    const calendarState = useContext(customCalendarContext);

    return (
        <div className="middle-container middle-container-services mb-4 px-lg-4 pt-lg-4 pb-lg-1" style={{ marginBottom: 20 }}>
            <div className="d-flex align-items-center justify-content-between">
                <h2>{title}</h2>
                <CustomButton
                    onClick={() => calendarState.handleShow(true, dataType)}
                    classNameType="mainWhite"
                    buttonText={t('services-management.table.detail-button')}
                />
            </div>
            {state?.consumptionState?.error ? (
                t('error.internal-error')
            ) : (
                <table className="table table-hover w-100 mt-3">
                    <thead>
                        <tr>
                            <th className="border-bottom-none"></th>
                            <th align="center">{dataType === 'apiCallsPerYear' ? 'Calls' : 'Consumption'}</th>
                            <th align="center">{t('services-management.table.pictures')}</th>
                            <th align="center">{t('services-management.table.videos')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{t('services-management.table.total')}</th>
                            <td align="center" data-title={dataType === 'apiCallsPerYear' ? 'Calls' : 'Consumption'}>
                                {dataType === 'apiCallsPerYear' ? (
                                    state?.dataConsumption?.totalApiCall || '-'
                                ) : state?.dataConsumption?.totalConsumptionSize ? (
                                    <p className={state?.dataConsumption?.totalConsumptionSize.toString().length > 5 ? 'small-number' : ''}>
                                        {convertSizeNumber(state?.dataConsumption?.totalConsumptionSize)}{' '}
                                        <span className="data-type">{convertSizeType(state?.dataConsumption?.totalConsumptionSize)}</span>
                                    </p>
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td align="center" data-title={t('services-management.table.pictures')}>
                                {dataType === 'apiCallsPerYear' ? (
                                    state?.dataConsumption?.totalPictureApiCall || '-'
                                ) : state?.dataConsumption?.totalConsumptionPictureSize ? (
                                    <p className={state?.dataConsumption?.totalConsumptionPictureSize.toString().length > 5 ? ' small-number' : ''}>
                                        {convertSizeNumber(state?.dataConsumption?.totalConsumptionPictureSize)}{' '}
                                        <span className="data-type">{convertSizeType(state?.dataConsumption?.totalConsumptionPictureSize)}</span>
                                    </p>
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td align="center" data-title={t('services-management.table.videos')}>
                                {dataType === 'apiCallsPerYear' ? (
                                    state?.dataConsumption?.totalVideoApiCall || '-'
                                ) : state?.dataConsumption?.totalConsumptionVideoSize ? (
                                    <p className={state?.dataConsumption?.totalConsumptionVideoSize.toString().length > 5 ? 'small-number' : ''}>
                                        {convertSizeNumber(state?.dataConsumption?.totalConsumptionVideoSize)}{' '}
                                        <span className="data-type">{convertSizeType(state?.dataConsumption?.totalConsumptionVideoSize)}</span>
                                    </p>
                                ) : (
                                    '-'
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th style={{ borderBottom: 0 }} className="bold">
                                {t('services-management.table.this-month')}
                            </th>
                            <td style={{ borderBottom: 0 }} align="center" data-title={dataType === 'apiCallsPerYear' ? 'Calls' : 'Consumption'}>
                                {dataType === 'apiCallsPerYear' ? (
                                    state?.dataConsumption?.currentMonthApiCall || '-'
                                ) : state?.dataConsumption?.currentMonthConsumptionSize ? (
                                    <p className={state?.dataConsumption?.currentMonthConsumptionSize.toString().length > 5 ? 'small-number' : ''}>
                                        {convertSizeNumber(state?.dataConsumption?.currentMonthConsumptionSize)}{' '}
                                        <span className="data-type">{convertSizeType(state?.dataConsumption?.currentMonthConsumptionSize)}</span>
                                    </p>
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td style={{ borderBottom: 0 }} align="center" data-title={t('services-management.table.pictures')}>
                                {dataType === 'apiCallsPerYear' ? (
                                    state?.dataConsumption?.currentMonthPictureApiCall || '-'
                                ) : state?.dataConsumption?.currentMonthConsumptionPictureSize ? (
                                    <p className={state?.dataConsumption?.currentMonthConsumptionPictureSize.toString().length > 5 ? 'small-number' : ''}>
                                        {convertSizeNumber(state?.dataConsumption?.currentMonthConsumptionPictureSize)}{' '}
                                        <span className="data-type">{convertSizeType(state?.dataConsumption?.currentMonthConsumptionPictureSize)}</span>
                                    </p>
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td style={{ borderBottom: 0 }} align="center" data-title={t('services-management.table.videos')}>
                                {dataType === 'apiCallsPerYear' ? (
                                    state?.dataConsumption?.currentMonthVideoApiCall || '-'
                                ) : state?.dataConsumption?.currentMonthConsumptionVideoSize ? (
                                    <p className={state?.dataConsumption?.currentMonthConsumptionVideoSize.toString().length > 5 ? 'small-number' : ''}>
                                        {convertSizeNumber(state?.dataConsumption?.currentMonthConsumptionVideoSize)}{' '}
                                        <span className="data-type">{convertSizeType(state?.dataConsumption?.currentMonthConsumptionVideoSize)}</span>
                                    </p>
                                ) : (
                                    '-'
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

const GlobalServicesData: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="row flex-row">
                <div className="col-12 col-lg-6">
                    <ApiCallAndConsumptionTableLayout title={t('services-management.table.title-api-call')} />
                </div>
                <div className="col-12 col-lg-6">
                    <ApiCallAndConsumptionTableLayout title={t('services-management.table.title-api-consumption')} />
                </div>
            </div>
        </>
    );
};

export default GlobalServicesData;
