import React, { useContext, useState } from 'react';
import CustomButton from '../../../components/button/button';
import { ReactComponent as PictureSvg } from '../../../static/icons/tasks/icon_picture.svg';
import { ReactComponent as PlateSvg } from '../../../static/icons/tasks/icon_plates.svg';
import { ReactComponent as PlateSvgActive } from '../../../static/icons/tasks/icon_plates_active.svg';
import { ReactComponent as FaceSvgActive } from '../../../static/icons/tasks/icon_face_active.svg';
import { ReactComponent as FaceSvg } from '../../../static/icons/tasks/icon_face.svg';
import { ReactComponent as CoordinatesSvg } from '../../../static/icons/tasks/coordinates.svg';
import { ReactComponent as CoordinatesSvgActive } from '../../../static/icons/tasks/coordinates_active.svg';
import pngImage from '../../../static/icons/tasks/icons8-zip-96.png';
import { useAxios } from '../../../services/hook/requestsService';
import FormData from 'form-data';
import '../../../static/scss/task-management.scss';
import { TaskComponentProps } from '../taskFactory';
import { useTranslation } from 'react-i18next';
import { NoPreview } from '../component/NoPreview';
import { clientContext } from '../../../context/clientContext';
import { useHeader } from '../../../hook/useHeader';
import Toggle from '../../../components/toggle/Toggle';
import DragNDrop from '../../../components/dragNDrop/DragNDrop';
import { useDragNDrop } from '../../../components/dragNDrop/useDragNDrop';
import useWindowDimensions from '../../../hook/useWindowDimensions';

type SVGElement = React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;

type OptionProps = {
    logo: {
        active: SVGElement;
        inactive: SVGElement;
    };
    traduction: string;
    handleChangeSwitch: () => void;
    isSwitchActive: boolean;
    value: any;
    disabled: boolean;
    height: number;
    width: number;
    className?: string;
    withInformation?: boolean;
};

const Tooltip = ({ show = true, message }: { show?: boolean; message: string }) => {
    if (!show) return null;
    return <div className="tooltip-container">{message}</div>;
};

const RequestOption: React.FC<OptionProps> = ({
    logo: { active: LogoActive, inactive: LogoInActive },
    traduction,
    isSwitchActive,
    handleChangeSwitch,
    value,
    disabled,
    height,
    width,
    className,
    withInformation,
}) => {
    const Logo = disabled ? LogoInActive : isSwitchActive ? LogoActive : LogoInActive;
    const [showTooltip, setShowTooltip] = useState(false);
    const { t } = useTranslation();
    const windowDimension = useWindowDimensions().width;

    const onMouseEnter = () => {
        setShowTooltip(true);
    };
    const onMouseLeave = () => {
        setShowTooltip(false);
    };

    const handleInformationPanel = () => {
        if (windowDimension < 992) {
            setShowTooltip(!showTooltip);
        }
        return;
    };

    return (
        <>
            <div className={'option-grid py-0' + className}>
                <div className="d-flex justify-content-center">
                    <Logo height={height} width={width} />
                </div>
                <span className={`d-flex justify-content-between align-items-center position-relative ${disabled ? 'disabled' : 'active'}`}>
                    {traduction}
                    {withInformation && (
                        <div
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            onClick={handleInformationPanel}
                            className={`information mr-lg-1 ${windowDimension < 992 && showTooltip ? 'active ' : ''}${disabled ? 'disabled' : ''}`}
                        >
                            <small>i</small>
                            {windowDimension > 992 && <Tooltip show={showTooltip} message={t('task-management.tasks.anonymization.json-information')} />}
                        </div>
                    )}
                </span>
                <div className="d-flex align-items-center">
                    <Toggle value={value} isChecked={isSwitchActive} onClick={handleChangeSwitch} disabled={disabled} />
                </div>
            </div>
            {withInformation && windowDimension < 992 && (
                <div className="information-panel" data-visible={showTooltip} aria-hidden={showTooltip}>
                    {t('task-management.tasks.anonymization.json-information')}
                </div>
            )}
        </>
    );
};

type PreviewProps = {
    onDrop: (file: File) => void;
    onDragEnter: (e: React.DragEvent<HTMLElement>) => void;
    onDragLeave: (e: React.DragEvent<HTMLElement>) => void;
    children: React.ReactNode;
};

const PreviewComponent: React.FC<PreviewProps> = ({ children, onDrop, onDragEnter, onDragLeave }) => {
    return (
        <DragNDrop onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
            {children}
        </DragNDrop>
    );
};

const AnonymizationTask: React.FC<TaskComponentProps> = ({ handleCancel, handleConfirm, handleError, setProgressUpload }) => {
    const dragndropStore = useDragNDrop();
    const client = useContext(clientContext);
    const [file, setFile] = useState<null | { fileUrl: string; file: any }>(null);
    const { width } = useWindowDimensions();
    const [checkboxSelected, setCheckboxSelected] = useState<any>({
        plates: true,
        faces: true,
        json: false,
    });
    const headerData = useHeader();
    const [isErrorSize, setIsErrorSize] = useState(false);
    const { t } = useTranslation();
    const { postData } = useAxios({ isPrivate: true });
    const onOpenFile = () => {
        if (dragndropStore.inputRef?.current) {
            dragndropStore.inputRef.current.click();
        }
    };

    const onDrop = (file: File) => {
        if (file) {
            setFile({ fileUrl: URL.createObjectURL(file), file });
            if (file.type.includes('zip')) {
                setCheckboxSelected({
                    ...checkboxSelected,
                    json: true,
                });
            }
        }
    };

    const onDragEnter = (e: React.DragEvent<HTMLElement>) => {};
    const onDragLeave = (e: React.DragEvent<HTMLElement>) => {};

    const handleChangeCheck = (name: string) => {
        setCheckboxSelected({
            ...checkboxSelected,
            [name]: !checkboxSelected[name],
        });
    };

    const onUploadProgress = (progressEvent: any) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgressUpload(percentCompleted);
    };

    const handlePostData = async (withMail?: boolean) => {
        try {
            const form = new FormData();
            form.append('input_media', file?.file);
            form.append('activation_faces_blur', checkboxSelected.faces);
            form.append('activation_plates_blur', checkboxSelected.plates);
            form.append('output_detections_url', checkboxSelected.json);
            form.append('origin', 'facelytics_app');
            if (withMail) {
                form.append('with_mail', true);
            }
            return await postData(null, 'task-management/anonymization', form, { onUploadProgress });
        } catch (e) {
            throw e;
        }
    };

    const submitTask = () => {
        const authorizedFormat = [
            'image/jpg',
            'image/jpeg',
            'video/mp4',
            'image/png',
            'video/x-msvideo',
            'application/zip',
            'application/x-zip-compressed',
            'application/zip-compressed',
            'video/avi',
            'video/x-matroska',
            'video/quicktime',
        ];
        if (
            client.dataClient?.isTrial &&
            headerData.getTrialState() - file?.file.size < 0 // trial remaining en octet MOINS la taille du fichier en octet
        ) {
            handleError({ response: { data: { message: 'trialLimitation' } } });
            return;
        }

        // correspond à 2 GB
        if (file?.file?.size > 21_474_836_480) {
            handleError({ message: 'task-management.error.fileTooLong' });
            return;
        } else {
            if (file?.file?.name.includes('.mkv') || file?.file?.name.includes('.MKV') || authorizedFormat.includes(file?.file?.type)) {
                handleConfirm('Anonymization', file?.file.size, handlePostData, true);
                return;
            } else {
                handleError(null, 'task-management.error.unsupported-format');
                return;
            }
        }
    };

    const getPreviewPictureColor = () => {
        return dragndropStore.dragging ? '#009BDE' : '#BFC7CE';
    };

    return (
        <div className="mx-lg-3 task-page">
            <div className="d-flex align-items-center flex-column">
                <div className="w-100 px-lg-4 d-flex row flex-row d-flex align-items-center options-container anonymization mb-lg-5 mt-3">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 py-2 py-lg-0 shadow-mobile border-radius-mobile">
                        <PreviewComponent onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
                            <h3 className="color-primary mb-3 mb-lg-0">{t('task-management.tasks.anonymization.select-file')}</h3>
                            <div
                                className={`preview d-flex flex-column align-items-center px-2 pt-2 ${
                                    file ? 'justify-content-end file-selected' : 'justify-content-center'
                                }`}
                            >
                                <div className="preview-image d-flex flex-column align-items-center justify-content-center">
                                    {!file ? (
                                        isErrorSize ? (
                                            <div className="d-flex flex-column">
                                                <p className="color-primary">{t('task-management.error.fileTooLong-title')}</p>
                                                <p className="color-primary">{t('task-management.error.fileTooLong')}</p>
                                            </div>
                                        ) : (
                                            <div
                                                style={{ border: `3px dashed ${getPreviewPictureColor()}` }}
                                                className="picture-container d-flex justify-content-center align-items-center"
                                            >
                                                <PictureSvg height={80} width={80} fill={getPreviewPictureColor()} />
                                            </div>
                                        )
                                    ) : ['application/zip', 'application/x-zip-compressed'].includes(file.file.type) ? (
                                        <img src={pngImage} height="96px" alt="anonymization-img" />
                                    ) : !file.file.type.includes('image') ? (
                                        file.file.type.includes('avi') ? (
                                            <NoPreview />
                                        ) : (
                                            <video width="100%" height="100%" className="clip-thumbnail" preload="auto" src={file.fileUrl} />
                                        )
                                    ) : (
                                        <img src={file.fileUrl} style={{ maxHeight: '100%', maxWidth: '100%' }} alt="anonymization-img" />
                                    )}
                                    {file && ['application/zip', 'application/x-zip-compressed', 'video/avi'].includes(file.file.type) && (
                                        <p className="color-primary mt-2">{file.file.name}</p>
                                    )}
                                </div>
                                <span className={`${!file ? 'color-clear-text' : 'color-white'}`}>
                                    {t('task-management.tasks.anonymization.select-file-laius')}
                                </span>
                                <span className={`${!file ? 'color-clear-text' : 'color-white'}`}>{t('task-management.tasks.anonymization.max-size')}</span>
                                <CustomButton
                                    classNameType={'main'}
                                    buttonText={
                                        !file ? t('task-management.tasks.anonymization.select-button') : t('task-management.tasks.anonymization.change-button')
                                    }
                                    customClass="mt-2 mb-2 mb-lg-4"
                                    onClick={onOpenFile}
                                />
                            </div>
                        </PreviewComponent>
                    </div>
                    <div className={`col-12 col-lg-6 options d-flex ${file ? 'file-selected' : ''} flex-column`}>
                        <h3>{t('task-management.tasks.anonymization.select-options-blur')}</h3>
                        <div style={{ height: `${width < 992 ? 'auto' : '400px'}` }} className="d-flex w-100 flex-column justify-content-between">
                            <div className="mt-2">
                                <RequestOption
                                    height={80}
                                    width={40}
                                    disabled={file ? false : true}
                                    logo={{ active: FaceSvgActive, inactive: FaceSvg }}
                                    isSwitchActive={checkboxSelected.faces}
                                    value={'faces'}
                                    handleChangeSwitch={() => {
                                        handleChangeCheck('faces');
                                    }}
                                    traduction={t('task-management.tasks.anonymization.faces')}
                                />
                                <RequestOption
                                    height={80}
                                    width={60}
                                    disabled={file ? false : true}
                                    logo={{ active: PlateSvgActive, inactive: PlateSvg }}
                                    isSwitchActive={checkboxSelected.plates}
                                    value={'plates'}
                                    handleChangeSwitch={() => {
                                        handleChangeCheck('plates');
                                    }}
                                    traduction={t('task-management.tasks.anonymization.plates')}
                                />
                                <RequestOption
                                    className=" mt-2"
                                    height={40}
                                    width={40}
                                    disabled={file?.file.type.includes('zip') ? true : file ? false : true}
                                    logo={{ active: CoordinatesSvgActive, inactive: CoordinatesSvg }}
                                    isSwitchActive={checkboxSelected.json}
                                    value={'json'}
                                    handleChangeSwitch={() => {
                                        handleChangeCheck('json');
                                    }}
                                    traduction={t('task-management.tasks.anonymization.json')}
                                    withInformation={true}
                                />
                            </div>
                            <div className="">
                                <i className="image-processing d-flex justify-content-center mb-4 mt-3 ">{t('task-management.tasks.image-processing')}</i>
                                <div className="d-flex justify-content-center align-items-center w-100">
                                    {
                                        <div>
                                            <CustomButton classNameType="mainWhite" buttonText="Cancel" customClass="mr-2" onClick={handleCancel} />
                                            <CustomButton classNameType="main" buttonText="Create" customClass="ml-2" disabled={!file} onClick={submitTask} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnonymizationTask;
