import React, { createContext, useReducer } from 'react';

type IinitialState = {
    head: [];
    body: [];
    selected: [];
    isLoaded?: boolean;
};

const initialState = {
    head: [],
    body: [],
    selected: [],
    isLoaded: false,
};

const context = createContext<{
    state: IinitialState;
    dispatch: React.Dispatch<any>;
}>({
    state: {} as IinitialState,
    dispatch: () => null,
});

const reducer = (state: any, action: any) => {
    const { type, target } = action;

    switch (type) {
        case 'INIT_TABLE': {
            return {
                ...state,
                head: target.head,
                body: target.body,
                isLoaded: true,
            };
        }
        case 'HANDLE_SELECTED': {
            return {
                ...state,
                selected: target,
            };
        }
        default:
            return state;
    }
};

const TableProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>;
};

export { context, TableProvider };
