import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { ReactComponent as DownloadSvg } from '../../../static/icons/icon_download-black.svg';

export const DownloadButton: React.FC<{ url: string; params?: any; buttonType: 'image' | 'link'; filename: string }> = ({
    url,
    params,
    filename,
    buttonType = 'image',
}) => {
    const [cookie] = useCookies(['Auth_manager']);
    const [iconState, setIconState] = useState({ color: '#000000' });

    const handleIconColor = (mouseAction: any) => {
        if (mouseAction === 'enter') {
            setIconState({ color: '#009BDE' });
        } else {
            setIconState({ color: '#000000' });
        }
    };
    const prepareRequestParameters = () => {
        let urlCopy = `${url}?`;
        if (params) {
            urlCopy += Object.keys(params)
                ?.map((param) => `${param}=${params[param]}`)
                ?.join('&');
            urlCopy += '&';
        }
        urlCopy += `authToken=${cookie['Auth_manager']?.token}`;
        return urlCopy;
    };
    return (
        <>
            <a href={prepareRequestParameters()}>
                {buttonType === 'image' ? (
                    <DownloadSvg
                        width={15}
                        height={15}
                        onMouseEnter={() => handleIconColor('enter')}
                        onMouseLeave={() => handleIconColor('leave')}
                        fill={iconState.color}
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                ) : (
                    <span className="file-name">{filename}</span>
                )}
            </a>
        </>
    );
};
