import { object, string } from 'yup';

const normalizeEmptyStrings = (curr: string | null, orig: string | null) => {
	return orig?.trim() === '' ? null : curr
}

const requiredMessage = () => {
	return "Required field"
}

const minMessage = (min: number) => {
	return `Must be at least ${min} characters`
}

const maxMessage = (max: number) => {
	return `Must be less than ${max} characters`
}

const baseValidationSchema = object().shape({
	contactLastName: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	contactFirstName: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	phone: string().min(10, minMessage(10)).max(30).nullable().transform(normalizeEmptyStrings).notRequired(),
});

export const trialValidationSchema = baseValidationSchema.shape({
	address: string().min(2, minMessage(2)).max(200, maxMessage(200)).nullable().transform(normalizeEmptyStrings).notRequired(),
	city: string().min(2, minMessage(2)).max(200, maxMessage(200)).nullable().transform(normalizeEmptyStrings).notRequired(),
	postalCode: string().min(2, minMessage(2)).max(200, maxMessage(200)).nullable().transform(normalizeEmptyStrings).notRequired(),
	country: string().min(2, minMessage(2)).max(200, maxMessage(200)).nullable().transform(normalizeEmptyStrings).notRequired(),
});

export const individualValidationSchema = baseValidationSchema.shape({
	address: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	city: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	postalCode: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	country: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
});

export const companyValidationSchema = baseValidationSchema.shape({
	address: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	city: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	postalCode: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	country: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	companyName: string().min(2, minMessage(2)).max(200, maxMessage(200)).required(requiredMessage()),
	vatNumber: string().min(2, minMessage(2)).max(30).nullable().transform(normalizeEmptyStrings).notRequired(),
	companyId: string().min(2, minMessage(2)).max(30).nullable().transform(normalizeEmptyStrings).notRequired()
});
