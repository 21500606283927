import { useContext, useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { socketContext } from '../../context/socketContext';

const apiUrl = process.env.REACT_APP_API_URL as string;

interface IResponse {
    loading: boolean;
    error: any;
    data: any;
}

//Hook to use axios
export const useAxios = ({ isPrivate = true }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<AxiosResponse | null>(null);
    const [cookie] = useCookies(['Auth_manager']);
    const { push } = useHistory();
    const socketStore = useContext(socketContext);

    //get data
    const getData = async (url: string | null, route: string, config?: AxiosRequestConfig): Promise<IResponse> => {
        if (!config) {
            config = {};
        }
        if (!url) {
            url = apiUrl;
        }
        if (isPrivate) {
            //@ts-ignore
            config.headers = {
                Authorization: `Bearer ${cookie['Auth_manager']?.token || null}`,
            };
        }
        try {
            setLoading(true);
            setError(false);
            setResponse(null);
            const { data } = await axios.get(`${url}/${route}`, config);
            setLoading(false);
            setError(false);
            setResponse(data);
            return {
                data,
                error,
                loading,
            };
        } catch (e: any) {
            setLoading(false);
            setError(true);
            setResponse(null);
            if (e?.response?.status === 401) {
                await socketStore?.disconnectSocket();
                push('/login');
            }
            return {
                data: null,
                error: e,
                loading,
            };
        }
    };

    //post data
    const postData = async (url: string | null, route: string, params?: any, config?: AxiosRequestConfig): Promise<IResponse> => {
        if (!config) {
            config = {};
        }
        if (!url) {
            url = apiUrl;
        }
        if (isPrivate) {
            //@ts-ignore
            config.headers = {
                Authorization: `Bearer ${cookie['Auth_manager']?.token || null}`,
            };
        }
        try {
            setLoading(true);
            setError(false);
            setResponse(null);
            const { data } = await axios.post(`${url}/${route}`, params, config);
            setLoading(false);
            setError(false);
            setResponse(data);
            return {
                data,
                error,
                loading,
            };
        } catch (e: any) {
            if (e?.response?.status === 401) {
                await socketStore?.disconnectSocket();
                push('/login');
            }
            setLoading(false);
            setError(true);
            setResponse(null);
            throw e;
        }
    };

    //post data
    const putData = async (url: string | null, route: string, params?: any, config?: AxiosRequestConfig): Promise<IResponse> => {
        if (!config) {
            config = {};
        }
        if (!url) {
            url = apiUrl;
        }
        if (isPrivate) {
            //@ts-ignore
            config.headers = {
                Authorization: `Bearer ${cookie['Auth_manager']?.token || null}`,
                'Content-Type': 'application/json',
            };
        }
        try {
            setLoading(true);
            setError(false);
            setResponse(null);
            const { data } = await axios.put(`${url}/${route}`, params, config);
            setLoading(false);
            setError(false);
            setResponse(data);
            return {
                data,
                error,
                loading,
            };
        } catch (e: any) {
            if (e?.response?.status === 401) {
                await socketStore?.disconnectSocket();
                push('/login');
            }
            setLoading(false);
            setError(true);
            setResponse(null);
            throw e;
        }
    };
    return { error, loading, response, postData, getData, putData };
};
