import React, { useContext, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CustomButton from '../../../components/button/button';
import { taskManagementContext } from '../../../context/TaskManagementContext';
import moment from 'moment';
import CustomSelect from '../../../components/select/CustomSelect';
import useWindowDimensions from '../../../hook/useWindowDimensions';

const fileTypeOptions = [
    //{ label: 'Video', value: 'VIDEO' },
    { label: 'Picture', value: 'PICTURE' },
    { label: 'Bulk', value: 'BULK' }
];
const searchKeysOptions = [
    { label: 'Anonymization', value: 'SRV_ANM' },
    { label: 'Watermark', value: 'SRV_WATERMARK' },
];

type MenuProps = {
    handleButtonService: (goTo: string) => void;
    canUseNavigation: boolean;
};

const Menu: React.FC<MenuProps> = ({ handleButtonService, canUseNavigation }) => {
    const { t } = useTranslation();
    const taskManagementStore = useContext(taskManagementContext);
    const ref = useRef<HTMLDivElement>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [minHeight, setMinHeight] = useState(0);
    const [overflow, setOverflow] = useState('');
    const [height, setHeight] = useState<number | string | undefined>(0);
    const dimension = useWindowDimensions();

    const handleOpenFilters = () => {
        const componentHeight = ref.current?.scrollHeight;
        if (!showFilters && componentHeight) {
            setMinHeight(componentHeight);
        } else {
            setOverflow('hidden');
            setMinHeight(0);
            setHeight(undefined);
        }
        setShowFilters(!showFilters);
    };

    const handleTransition = () => {
        if (showFilters) {
            setHeight('inherit');
            setOverflow('inherit');
        } else {
            setHeight(0);
        }
    };

    const handleFilter = (name: string, select: any) => {
        taskManagementStore.handleFilter(name, select);
    };

    const handleClearFilter = () => {
        taskManagementStore.handleClear();
        taskManagementStore.handleRefetch(true);
    };

    return (
        <div className="menu d-none d-lg-flex" style={{ width: `${dimension.width - 266 - (dimension.width * 3) / 100}px` }}>
            <div className="tasks">
                {/*<CustomButton
                    buttonType="button"
                    classNameType="desktopTask"
                    onClick={() => handleButtonService('/task-manager/anonymization')}
                    buttonText={
                        (
                            <>
                                {t('task-management.go-to-anonymization')}
                                <br />
                                {t('task-management.go-to-file')}
                            </>
                        ) as React.ReactNode
                    }
                    customClass={`anonymization mr-1 ${!canUseNavigation ? 'custom-disabled' : ''}`}
                    style={{ maxWidth: '200px' }}
                />
                <CustomButton
                    buttonType="button"
                    classNameType="desktopTask"
                    onClick={() => handleButtonService('/task-manager/watermark')}
                    buttonText={
                        (
                            <>
                                {t('task-management.go-to-watermark')}
                                <br />
                                {t('task-management.go-to-file')}
                            </>
                        ) as React.ReactNode
                    }
                    customClass={`watermark mr-1 ${!canUseNavigation ? 'custom-disabled' : ''}`}
                    style={{ maxWidth: '200px' }}
                />*/}
                <CustomButton
                  buttonType="button"
                  classNameType="desktopTask"
                  onClick={() => handleButtonService('/task-manager/faces-attributes')}
                  buttonText={
                      (
                        <Trans i18nKey={`task-management.button-detect`}></Trans>
                      ) as React.ReactNode
                  }
                  customClass={`faces-attributes ${!canUseNavigation ? 'custom-disabled' : ''}`}
                />
            </div>
            <div className="filters">
                <div className="filters-header" onClick={handleOpenFilters}>
                    <div className="filters-title">
                        <h2>{t('task-management.filters')}</h2>
                    </div>
                    <button className={`arrow-collapse ${showFilters ? 'open' : ''}`}></button>
                </div>
                <div
                    className="filters-grid"
                    ref={ref}
                    onTransitionEnd={handleTransition}
                    style={{
                        height,
                        minHeight: `${minHeight + (showFilters ? 20 : 0)}px`,
                        transition: 'min-height 300ms ease-in-out',
                        overflow,
                        marginTop: `${showFilters ? 15 : 0}px`,
                    }}
                >
                    <div className="column-1">
                        <div className="d-flex flex-column">
                            <span className="mb-1">{t('task-management.filters-starting-date')}</span>
                            <input
                                value={taskManagementStore.filterState.dateStart}
                                max={moment().format('YYYY-MM-DD')}
                                type="date"
                                name="startDate"
                                placeholder={t('task-management.filters-starting-date')}
                                onChange={(e) => handleFilter('dateStart', e)}
                                className={`${taskManagementStore.filterState?.dateStart ? 'has-value' : ''}`}
                                style={{ minWidth: '180px' }}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <span className="mb-1">{t('task-management.filters-ending-date')}</span>
                            <input
                                value={taskManagementStore.filterState.dateEnd}
                                type="date"
                                name="endDate"
                                placeholder={t('task-management.filters-ending-date')}
                                onChange={(e) => handleFilter('dateEnd', e)}
                                disabled={!taskManagementStore.filterState?.dateStart}
                                min={taskManagementStore.filterState?.dateStart ?? undefined}
                                className={`${taskManagementStore.filterState?.dateEnd ? 'has-value' : ''}`}
                                style={{ minWidth: '180px' }}
                            />
                        </div>
                    </div>
                    <div className="column-2">
                        <div>
                            <span>{t('task-management.filters-file-type')}</span>
                            <div className="select-container">
                                <CustomSelect
                                    onChange={(e) => handleFilter('fileType', e)}
                                    value={taskManagementStore.filterState.fileType}
                                    placeholder={t('task-management.filters-file-type')}
                                    options={
                                        taskManagementStore.filterState.fileType ? [{ value: undefined, label: 'Clear' }, ...fileTypeOptions] : fileTypeOptions
                                    }
                                />
                            </div>
                        </div>
                        {/*<div>
                            <span>{t('task-management.filters-service-name')}</span>
                            <div className="select-container">
                                <CustomSelect
                                    onChange={(e) => handleFilter('searchKey', e)}
                                    value={taskManagementStore.filterState.searchKey}
                                    placeholder={t('task-management.filters-service-name')}
                                    options={
                                        taskManagementStore.filterState.searchKey
                                            ? [{ value: undefined, label: 'Clear' }, ...searchKeysOptions]
                                            : searchKeysOptions
                                    }
                                />
                            </div>
                        </div>*/}
                    </div>
                    <div className="column-3">
                        <CustomButton
                            buttonType="button"
                            classNameType="mainWhite"
                            onClick={handleClearFilter}
                            customClass="mb-2"
                            buttonText={t('task-management.clear-filter-button')}
                        />
                        <CustomButton
                            buttonType="button"
                            classNameType="main"
                            onClick={() => taskManagementStore.handleRefetch(true)}
                            buttonText={t('task-management.search-button')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;
