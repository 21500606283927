import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { convertSize } from '../../../services/tools';
import { BadgeStatus } from '../../../pages/taskManager/helper/formatTable';
import { DownloadButton } from '../../../pages/account/utils/downloadButton';
import moment from 'moment';
import useWindowDimensions from '../../../hook/useWindowDimensions';

// Icons
import { ReactComponent as IconBack } from '../../../static/icons/icon-back-mobile.svg';

// Style
import './TaskManagerDetail.scss';

type TTask = {
    title: string;
    data: string;
    status?: boolean;
};

const TaskManagerDetail: React.FC = () => {
    const { t } = useTranslation();
    // State from /task-manager
    const { state }: { state: any } = useLocation();
    const { push } = useHistory();
    const { width } = useWindowDimensions();

    const task = state.element;

    // Badge status
    const getBadgeSatus = () => {
        switch (task.status) {
            case 'Succeeded': {
                return BadgeStatus.SUCCESS;
            }
            case 'Deleted': {
                return BadgeStatus.SUCCESS;
            }
            case 'None': {
                return BadgeStatus.NONE;
            }
            case 'Sent': {
                return BadgeStatus.PROCESSING;
            }
            case 'Started': {
                return BadgeStatus.PROCESSING;
            }
            case 'Uploading': {
                return BadgeStatus.PROCESSING;
            }
            case 'Failed': {
                return BadgeStatus.FAILED;
            }
            case 'Upload failed': {
                return BadgeStatus.FAILED;
            }
            default:
                return BadgeStatus.NONE;
        }
    };

    const formatFileName = (element: any) => {
        if (element.status === 'Succeeded' && element.filename) {
            return `${element.filename.slice(0, element.filename.lastIndexOf('.'))}_processed_${moment(element.date)
                .utc()
                .format('YYYYMMDDhhmmss')}z${element.filename.slice(element.filename.lastIndexOf('.'), element.filename.length)}`;
        } else {
            return element.filename;
        }
    };

    const isTaskSucceeded = (status: string) => {
        return status === 'Succeeded';
    };

    // Task list to map
    const taskList: TTask[] = [
        {
            title: t('task-management-detail.list.status'),
            data: state.element.status,
            status: true,
        },
        {
            title: t('task-management-detail.list.date'),
            data: moment(task.date).format('DD-mm-yyy:HH:mm:ss'),
        },
        {
            title: t('task-management-detail.list.service'),
            data: state.element.service_name,
        },
        {
            title: t('task-management-detail.list.fileType'),
            data: state.element.file_type,
        },
        {
            title: t('task-management-detail.list.fileName'),
            data: formatFileName(task),
        },
        {
            title: t('task-management-detail.list.fileSize'),
            data: convertSize(state.element.size),
        },
    ];

    // Redirect if device width > 992px
    if (width > 992) {
        push('/task-manager');
    }

    return (
        <>
            <h2 className="title-up-container" onClick={() => push('/task-manager')}>
                <IconBack fill="#6B7897" />
                {t('task-management-detail.title')}
            </h2>

            <div className="middle-container middle-container-detail">
                <ul className="middle-container-detail-list">
                    {taskList.map((element, index: number) => (
                        <li key={index}>
                            <h5 className="list-title">{element.title}</h5>
                            {element.status ? (
                                <div className="task-status">
                                    <span className={`status ${getBadgeSatus()}`}></span>
                                    {element.data}
                                </div>
                            ) : (
                                element.data.split('/')[0]
                            )}
                        </li>
                    ))}
                    <li className="item-buttons">
                        <h5 className="list-title">{t('task-management-detail.list.downloads')}</h5>
                        {/**
                        is_json: true,
                                      searchKey: element.service_search_key,
                                      job_id: job_id,
                                      fileType: element.file_type,
                                      fileName: formattedFileName,
                        
                         */}
                        <div className="button-grp justify-between">
                            <button type="button" className="button btn-mobile-task" disabled={task.parameters ? !task.parameters.output_detections_url : true}>
                                <DownloadButton
                                    params={{
                                        searchKey: task.service_search_key,
                                        job_id: task.job_id,
                                        fileType: task.file_type,
                                        is_json: true,
                                        fileName: `${task.filename.slice(0, task.filename.lastIndexOf('.'))}.json`,
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${task.fileurl}`}
                                    filename={`${task.filename.slice(0, task.filename.lastIndexOf('.'))}.json`}
                                    buttonType="image"
                                />
                                {t('task-management-detail.button.coordinates')}
                            </button>

                            <button type="button" className="button btn-mobile-task" disabled={!isTaskSucceeded(task.status)}>
                                <DownloadButton
                                    params={{
                                        searchKey: task.service_search_key,
                                        is_json: true,
                                        job_id: task.job_id,
                                        fileType: task.file_type,
                                        fileName: formatFileName(task),
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${task.fileurl}`}
                                    filename={formatFileName(task)}
                                    buttonType="image"
                                />
                                {t('task-management-detail.button.file')}
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default TaskManagerDetail;
